import React, { useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import { Form, FormFeedback } from "reactstrap";
import { useBrokerLoginStore } from '../../../store/Authentication/broker';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createAPiEndpoint } from '../../../utils/apiEndpoints';
import { toast } from 'react-toastify';
import { getFromStorage } from '../../../utils/storage';
import { useHistory } from 'react-router-dom';
import { useVerificationStore } from '../../../store/Authentication/VerificationStore';


const PersonalDetails = () => {
    const [loading, setLoading] = useState(false)
    const openPersonalDetailModel = useBrokerLoginStore(
        (state) => state.openPersonalDetailModel
    );
    const setShowModal = useVerificationStore(
        (state) => state.setShowModal
    );

    const setOpenPersonalDetailModel = useBrokerLoginStore(
        (state) => state.setOpenPersonalDetailModel
    );
    const setPersonalDetailsData = useBrokerLoginStore(
        (state) => state.setPersonalDetailsData
    );
    const phoneRegExp =
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;


    // const captureData = (data) => {
    //     setLoading(true)
    //     fetch(createAPiEndpoint('paytmUserUpdate', `?id=${getFromStorage("userDetails")?.id}`), {
    //         method: "post",
    //         headers: new Headers({
    //             Authorization: "Basic " + btoa(`appuser@injin.com:N_w?Z92w%-BuLvKX`),
    //             "Content-Type": "application/json",
    //         }),
    //         body: JSON?.stringify(data),
    //     })
    //         .then((r) => r.json().then((resp) => ({ status: r.status, body: resp })))
    //         .then((obj) => {
    //             setLoading(false)
    //             if (obj?.status == 200) {
    //                 if (getFromStorage("mobiletoweb")) {
    //                     window.ReactNativeWebView.postMessage(JSON.stringify({ redirectTo: 'app' }));
    //                 } else {
    //                     history?.push("/inbox");
    //                 }
    //                 setOpenPersonalDetailModel(false);
    //                 toast.success(obj?.body?.message, {
    //                     position: "top-right",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "colored",
    //                 });

    //             } else {
    //                 toast.error(obj?.body?.message, {
    //                     position: "top-right",
    //                     autoClose: 5000,
    //                     hideProgressBar: false,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                     theme: "colored",
    //                 });
    //             }
    //         }).catch((err) => {
    //             console.log(err);
    //             setLoading(false)
    //             toast.error("Error Occurred", {
    //                 position: "top-right",
    //                 autoClose: 5000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: "colored",
    //             });
    //         })
    // };


    const checkUser = () => {
        setLoading(true)
        const payloadData = {
            "phonenumber": validation?.values?.mobile,
            "email": validation?.values?.email,
            id: getFromStorage("userDetails")?.id
        }
        fetch(createAPiEndpoint('checkUserBroker'), {
            method: "post",
            headers: new Headers({
                Authorization: "Basic " + btoa(`appuser@injin.com:N_w?Z92w%-BuLvKX`),
                "Content-Type": "application/json",
            }),
            body: JSON?.stringify(payloadData),
        })
            .then((r) => r.json().then((resp) => ({ status: r.status, body: resp })))
            .then((obj) => {
                setLoading(false)
                if (obj?.status == 200) {
                    verifyUser()

                } else {
                    setLoading(false)
                    toast.error(obj?.body?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }).catch((err) => {
                console.log(err);
                setLoading(false)
                toast.error("Error Occurred", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
    };

    const resendOtpToUser = () => {
        setLoading(true)
        const payloadData = {
            "phonenumber": validation?.values?.mobile,
            "type": "broker-verification",
            "email": validation?.values?.email,
            "authenticationPlatform": "broker"
        }
        fetch(createAPiEndpoint('resendOtp'), {
            method: "post",
            headers: new Headers({
                Authorization: "Basic " + btoa(`appuser@injin.com:N_w?Z92w%-BuLvKX`),
                "Content-Type": "application/json",
            }),
            body: JSON?.stringify(payloadData),
        })
            .then((r) => r.json().then((resp) => ({ status: r.status, body: resp })))
            .then((obj) => {
                setLoading(false)
                if (obj?.status == 200) {
                    setOpenPersonalDetailModel(false);
                    setShowModal(true)
                    const getForm = {
                        email: validation?.values.email,
                        phonenumber: validation?.values.mobile,
                        broker_id: getFromStorage("userDetails")?.broker_id
                    };
                    setPersonalDetailsData(getForm)
                    toast.success(obj?.body?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                } else {
                    setLoading(false)
                    toast.error(obj?.body?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }).catch((err) => {
                console.log(err);
                setLoading(false)
                toast.error("Error Occurred", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
    };


    const verifyUser = () => {
        setLoading(true)
        const payloadData = {
            "phonenumber": validation?.values?.mobile,
            "type": "broker-verification",
            "email": validation?.values?.email,
            "authenticationPlatform": "broker"
        }
        fetch(createAPiEndpoint('verifyPaytmUser'), {
            method: "post",
            headers: new Headers({
                Authorization: "Basic " + btoa(`appuser@injin.com:N_w?Z92w%-BuLvKX`),
                "Content-Type": "application/json",
            }),
            body: JSON?.stringify(payloadData),
        })
            .then((r) => r.json().then((resp) => ({ status: r.status, body: resp })))
            .then((obj) => {
                setLoading(false)
                if (obj?.status == 200) {
                    setOpenPersonalDetailModel(false);
                    setShowModal(true)
                    const getForm = {
                        email: validation?.values.email,
                        phonenumber: validation?.values.mobile,
                        broker_id: getFromStorage("userDetails")?.broker_id
                    };
                    setPersonalDetailsData(getForm)
                    toast.success(obj?.body?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                } else {
                    // setLoading(false)
                    resendOtpToUser()
                    // toast.error(obj?.body?.message, {
                    //     position: "top-right",
                    //     autoClose: 5000,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    //     theme: "colored",
                    // });
                }
            }).catch((err) => {
                resendOtpToUser();
                // setLoading(false)
                // toast.error("Error Occurred", {
                //     position: "top-right",
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "colored",
                // });
            })
    };


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
            mobile: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Email is Invalid")
                .required("Please Enter Your Email"),
            mobile: Yup.string()
                .matches(phoneRegExp, "Phone number is not valid")
                .min(10, "Please write a valid contact number")
                .max(10, "too long")
                .required("Please Enter Your Mobile"),
        }),
        onSubmit: (values) => {
            // captureEvent("Clicked on Signup");
            // const getForm = {
            //     email: values.email,
            //     phonenumber: values.mobile,
            //     broker_id: getFromStorage("userDetails")?.broker_id
            // };
            checkUser();
        },
    });

    const handleErrorMessage = () => {
        toast.error("Please fill in your details to proceed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    return (
        <Dialog
            className="comp-modal typ-request"
            open={openPersonalDetailModel}
        // onClose={() => setOpenPersonalDetailModel(false)}
        >
            <div className="modal-head">
                <div style={{ textAlign: "center", marginBottom: "10px" }}>
                    <h2 class="title">Welcome to Hedged !</h2>
                    <p className="desc" style={{ color: "#fff", fontSize: "13px", lineHeight: "16px" }}>
                        Please enter your Email and Mobile number to automatically get upgraded to being our Premium Crown Customer
                    </p>
                </div>

                <span className="close-modal" onClick={handleErrorMessage}>
                    <span class="icon-close"></span>
                </span>
            </div>
            <DialogContent style={{ height: "230px" }}>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <div className="form">
                        <div className="form-group">
                            <TextField
                                label="Enter Email Address"
                                variant="filled"
                                id="email"
                                name="email"
                                type="email"
                                placeholder="Enter email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                    validation.touched.email && validation.errors.email
                                        ? true
                                        : false
                                }
                                validation={validation}
                            />

                            {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid" style={{ color: "red" }}>
                                    {validation.errors.email}
                                </FormFeedback>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <TextField
                                label="Enter Mobile Number"
                                variant="filled"
                                id="mobile"
                                name="mobile"
                                type="mobile"
                                placeholder="Enter Mobile Number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mobile || ""}
                                invalid={
                                    validation.touched.mobile &&
                                        validation.errors.mobile
                                        ? true
                                        : false
                                }
                                validation={validation}
                            />

                            {validation.touched.mobile &&
                                validation.errors.mobile ? (
                                <FormFeedback type="invalid" style={{ color: "red" }}>
                                    {validation.errors.mobile}
                                </FormFeedback>
                            ) : null}
                        </div>
                        <div className="action-wrap align-center mt-10">
                            <button
                                onClick={validation.handleSubmit}
                                className="btn btn-primary"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </Form>
            </DialogContent>
        </Dialog >
    )
}

export default PersonalDetails