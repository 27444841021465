import React, { useEffect } from 'react';
import Footer from '../Common/Footer/Footer';
import Header from '../Common/Header/AuthHeader';
import { getFromStorage } from '../../utils/storage';
import { useMobileToWeb } from '../../store/Positions/mobileToWeb';
// import { useMobileToWeb } from '../../store/Positions/mobileToWeb';

const AuthLayout = (props) => {

  const isMobileToWeb = useMobileToWeb(
    (state) => state.isMobileToWeb
  );



  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      {props.children}
      {(isMobileToWeb || getFromStorage("mobiletoweb") || getFromStorage("viewPaytm")) ?
        <></>
        :
        <Footer />
      }
    </React.Fragment>
  );
};

export default AuthLayout;
