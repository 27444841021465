// import React, { useEffect, useState } from "react";
// import Drawer from "@mui/material/Drawer";
// import { Scrollbars } from "react-custom-scrollbars";
// import { Link, useHistory } from "react-router-dom";
// import { Nav, NavLink, NavMenu, NavBtn, NavBtnLink } from "reactstrap";
// import { deleteFromStorage, saveToStorage } from "../../../utils/storage";
// import { getFromStorage } from "../../../utils/storage";
// import { googleLogout } from "@react-oauth/google";
// import API from "../../../utils/APIs";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
// import {
//   captureEvent,
//   constants,
//   userLogoutCleverTap,
// } from "../../../utils/helper";
// import moment from "moment/moment";
// import { useBrokerLoginStore } from "../../../store/Authentication/broker";
// import { useCallData } from "../../../store/Calls/call";
// import { toast } from "react-toastify";
// import { Helmet } from "react-helmet";
// import { useLocation } from "react-router-dom/cjs/react-router-dom";
// import { useMobileToWeb } from "../../../store/Positions/mobileToweb";

// // import logo from '../logo/bg-siteLogo.svg';

// const Header = () => {
//   const history = useHistory();
//   const location = useLocation();
//   const [brokerList, setBorkerList] = useState([]);
//   // document.title = "Hedged | Header";
//   const username = getFromStorage("userDetails");
//   const userDetails = getFromStorage("userDetails");
//   const setOpenBrokerModel = useBrokerLoginStore(
//     (state) => state.setOpenBroker
//   );
//   const setBrokerUserList = useBrokerLoginStore(
//     (state) => state.setBrokerUserList
//   );

//   const brokerUserList = useBrokerLoginStore((state) => state.brokerUserList);
//   const userData = useBrokerLoginStore((state) => state.userData);
//   const setUserDetails = useBrokerLoginStore((state) => state.setUserData);
//   const setOpenConfirmModel = useBrokerLoginStore(
//     (state) => state.setOpenConfirmModel
//   );
//   const isMobileToWeb = useMobileToWeb(
//     (state) => state.isMobileToWeb
//   );


//   const isZerodha = brokerUserList?.filter((item)=> item?.broker == "zerodha" && item?.isAvailable)

//   const handleLogout = () => {
//     deleteFromStorage("token");
//     deleteFromStorage("userDetails");
//     deleteFromStorage("activeLtps");
//     history.push("/login");
//     // window.location.reload();
//     googleLogout();
//     userLogoutCleverTap("logout");
//     captureEvent("clicked logout");
//   };

//   const [currentUpdateDrawer, setCurrentUpdate] = useState(false);
//   const [searchDrawerOpen, setSearchDrawer] = useState(false);
//   const [value, setValue] = useState(0);
//   const [notiFicationList, setNotiFicationList] = useState([]);
//   const [expand, setExpand] = useState(false);
//   // const [openConfirmModel, setConfirmModel]=useState(false)
//   const callData = useCallData((state) => state.callData);

//   const currentUpdateClick = () => {
//     if (currentUpdateDrawer == false) {
//       setCurrentUpdate(true);
//       getNotification();
//       // currentUpdate(id);
//     } else {
//       setCurrentUpdate(false);
//     }
//   };
//   const searchDrawerClick = () => {
//     if (searchDrawerOpen == false) {
//       setSearchDrawer(true);
//     } else {
//       setSearchDrawer(false);
//     }
//   };

//   const getNotification = () => {
//     API.notificationList()
//       .then((response) => {
//         setNotiFicationList(response?.data?.data);
//       })
//       .catch((err) => {});
//   };

//   const getBroker = () => {
//     fetch(`${constants?.broker_base_Url}user/get_brokers`, {
//       method: "get",
//       headers: new Headers({
//         Authorization: "Bearer " + userDetails?.accessToken,
//         "Content-Type": "application/json",
//       }),
//     })
//       .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
//       .then((obj) => {
//         setBorkerList(obj?.body);
//         setBrokerUserList(obj?.body);
//         //  console.log("obj", obj);
//       })
//       .catch((e) => {});
//   };

//   const userProfile = () => {
//     API.getUSerProfile()
//       .then((response) => {
//         // setUserData(response?.data?.data);
//         setUserDetails(response?.data?.data);
//         if (
//           response?.data?.data &&
//           response?.data?.data?.broker_integration == 1
//         ) {
//           getBroker();
//         }
//       })
//       .catch((err) => {
//         // setError(true);
//       });
//   };

//   const handleExpand = () => {
//     setExpand(!expand);
//   };

//   const handleMetaTags =()=>{
//     let canonicalLink = document?.querySelector('link[rel="canonical"]');

//     if (!canonicalLink) {
//       canonicalLink = document.createElement("link");
//       canonicalLink.rel = "canonical";
//       document.head.appendChild(canonicalLink);
//     }

//     // Set the canonical URL dynamically
//     const canonicalURL = `https://hedged.in${window?.location?.pathname}`;
//     canonicalLink.href = canonicalURL;
//     let dynamicDescription;

//     const currentPath = window?.location?.pathname;
//     // Define a default title
//     // let dynamiCcanonicalURL;

//     // Check if the URL path starts with "/call-details/view/"
//     if (currentPath?.startsWith("/call-details/")) {
//       dynamicDescription = "Details";
//     }else{
//     switch (location?.pathname) {
//       case "/inbox":
//         dynamicDescription =
//           "Access Trade updates & alerts from Hedged directly in your inbox. Stay informed and up-to-date about our various Live funds as well.";
//         break;
//       case "/livefunds":
//         dynamicDescription =
//           "Invest in unique Live FundsTM, with built-in hedges &amp; set of 3-7 stocks for medium-term. Manage them in just 5 mins a month! Stay informed with Hedged.";
//         break;
//       case "/hedgedTVList":
//         dynamicDescription =
//           "Tired of the clutter on Youtube! Watch expert market analysis, trading strategies, and more on our very own OTT HedgedTV.";
//         break;
//       case "/news":
//         dynamicDescription =
//           "Stay up-to-date with the latest updates in the stock market with Hedged. Get expert insights and analysis on market trends, company announcements, and more.";
//         break;
//       case "/subscription":
//         dynamicDescription =
//           "Hedged offers different subscription plans to suit everyone's Pocket ! Start with as low as 599/- per month and learn the Logic behind our trades.";
//         break;
//       default:
//         dynamicDescription="Hedged Investing and Trading for everyone";
//         break;
//     }
//   }

//     const descriptionMetaTag = document?.querySelector(
//       'meta[name="description"]'
//     );
//     if (descriptionMetaTag) {
//       descriptionMetaTag.setAttribute("content", dynamicDescription);
//     }

//     const descriptionPropertyTag = document?.querySelector(
//       'meta[property="og:description"]'
//     );
//     if (descriptionPropertyTag) {
//       descriptionPropertyTag.setAttribute("content", dynamicDescription);
//     }
//   }

//   useEffect(() => {
//     userProfile();
//     handleMetaTags()
//   }, []);

//   class CustomScrollbars extends React.Component {
//     render() {
//       return (
//         <Scrollbars
//           className="scrollbar-container"
//           renderTrackVertical={({ style, ...props }) => (
//             <div
//               {...props}
//               style={{ ...style, width: '10px' }}
//               className="scrollbar-vertical test-1"
//             />
//           )}
//           renderThumbVertical={({ style, ...props }) => (
//             <div
//               {...props}
//               style={{ ...style }}
//               className="thumb-vertical"
//             />
//           )}
//           renderView={({ style, ...props }) => (
//             <div
//               {...props}
//               style={{ ...style }}
//               className="view"
//             />
//           )}
//         >
//           {this.props.children}
//         </Scrollbars>
//       );
//     }
//   }

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   function a11yProps(index) {
//     return {
//       id: `simple-tab-${index}`,
//       "aria-controls": `simple-tabpanel-${index}`,
//     };
//   }

//   function TabPanel(props) {
//     const { children, value, index, ...other } = props;

//     return (
//       <div
//         role="tabpanel"
//         hidden={value !== index}
//         id={`simple-tabpanel-${index}`}
//         aria-labelledby={`simple-tab-${index}`}
//         {...other}
//       >
//         {value === index && (
//           <Box sx={{ p: 3 }}>
//             <Typography>{children}</Typography>
//           </Box>
//         )}
//       </div>
//     );
//   }

//   const [classAdd, setClassAdd] = useState(false);
//   const handleMenu = () => {
//     setClassAdd(true);
//   };

//   const handleCT = () => {
//     history.push("/hedgedTVList");
//     captureEvent("Clicked on Hedged TV");
//   };

//   const subscrptionPage = () => {
//     history.push("/subscription");
//     captureEvent("Subscription Page Viewed");
//   };

//   const handleInbox = () => {
//     history.push("/inbox");
//     captureEvent("clicked to navigate to Inbox screen");
//   };

//   const hadleHedgedResult = () => {
//     history.push("/hedged-result");
//   };

//   const handleOpenModel = () => {
//     setOpenBrokerModel(true);
//   };

//   const currentDate = new Date();
//   const isExpired = (expiryDateTime) => {
//     const expiryDate = new Date(expiryDateTime);
//     return currentDate > expiryDate;
//   };

//   const generate_login_url = (name)=>{
//     let loginUrl;
//     switch (name) {
//       case "zerodha":
//         loginUrl =
//           "zerodha/zerodha_generate_login_url";
//           break;
//       case "paytmMoney":
//         loginUrl =
//           "paytm/paytm_generate_login_url";
//           break;
//       case "dhan":
//         loginUrl =
//           "dhan/dhan_generate_consent";
//         break;
//         case "5paisa":
//         loginUrl =
//           "5paisa/5paisa_generate_login_url";
//         break;
//       default:
//         loginUrl="paytm/paytm_generate_login_url";
//         break;
//     }

//     return loginUrl;
//   }

//   const activate_authrisation = (name)=>{
//     let loginUrl;
//     switch (name) {
//       case "zerodha":
//         loginUrl =
//           "zerodha/authorize_tpin";
//           break;
//       case "dhan":
//         loginUrl =
//           "dhan/authorize_tpin";
//         break;
//       default:
//         loginUrl="zerodha/authorize_tpin";
//         break;
//     }

//     return loginUrl;
//   }


//   const brokerLogin = async (item) => {
//     fetch(`${constants?.broker_base_Url}${generate_login_url(item?.broker)}`, {
//       method: "get",
//       headers: new Headers({
//         Authorization: "Bearer " + userDetails?.accessToken,
//         "Content-Type": "application/json",
//       }),
//     })
//       .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
//       .then((obj) => {
//         //  console.log("obj", obj);
//         window.open(obj?.body?.loginUrl ?obj?.body?.loginUrl : obj?.body?.redirectTo, "_self");
//         // localStorage.setItem("prevPath", location?.pathname)
//         saveToStorage("prevPath", location?.pathname)
//       })
//       .catch((e) => {});
//   };



//   const handleBrokerLogin = (item) => {
//     brokerLogin(item);
//   };


//   const handleDeRegister = (item) => {
//     setOpenConfirmModel({
//       open: true,
//       loginBroker: item?.broker,
//     });
//     // deRegister(item?.broker)
//   };

//   const name = username?.name?.split(/[ _]/);

//   const initials = name?.map((word) => word.charAt(0)?.toUpperCase())?.join("");

//   const handleAuthrization =(name)=>{
//     fetch(
//       `${
//         constants?.broker_base_Url}${activate_authrisation(name)}`,
//       {
//         method: "get",
//         headers: new Headers({
//           Authorization: "Bearer " + userDetails?.accessToken,
//           "Content-Type": "application/json",
//         }),
//       }
//     )
//     .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
//     .then((obj) => {
//       //  console.log("obj", obj);
//       if(obj?.status == 200){
//         console.log("obj?.body", obj?.body);
//         window.open(obj?.body?.redirect_url, "_blank");
//       }else{
//         toast.error(obj?.body?.message, {
//           position: "top-right",
//           autoClose: 5000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         });
//       }
//     })
//     .catch((e) => {});
//   }

//   const handleDhanAuthrization =(name)=>{
//     fetch(
//       `${
//         constants?.broker_base_Url}${activate_authrisation(name)}`,
//       {
//         method: "get",
//         headers: new Headers({
//           Authorization: "Bearer " + userDetails?.accessToken,
//           "Content-Type": "application/json",
//         }),
//       }
//     )
//     .then((r) =>
//     r.text().then((data) => ({ status: r.status, body: data }))
//   )
//     .then((obj) => {
//       //  console.log("obj", obj);
//       if(obj?.status == 200){
//         const generatedHtml = obj?.body;
//             const blob = new Blob([generatedHtml], { type: "text/html" });
//             const url = URL.createObjectURL(blob);
//             window.open(url, "_target");
//       }else{
//         toast.error(obj?.body?.message, {
//           position: "top-right",
//           autoClose: 5000,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "colored",
//         });
//       }
//     })
//     .catch((e) => {});
//   }

//   // console.log(location?.pathname(), "location");

//   const Title = () => {
//     const currentPath = window.location.pathname;

//     // Define a default title
//     let dynamicTitle;

//     // Check if the URL path starts with "/call-details/view/"
//     if (currentPath.startsWith("/call-details/")) {
//       dynamicTitle = "Details";

//       return dynamicTitle
//     }else{
//       switch (location.pathname) {
//         case "/inbox":
//           return "Inbox";
//         case "/livefunds":
//           return "Simply Hedged";
//         case "/hedgedTVList":
//           return "HedgedTV";
//         case "/news":
//           return "News";
//         case "/subscription":
//           return "Subscribe Now";
//         case "/positions":
//           return "My Positions";
//         case "/user-edit-profile":
//           return "Edit Profile";
//         case "/user-edit-profile":
//           return "Edit Profile";
//         case "manage-track-list":
//           return "Manage Track";
//         case "/my-videos":
//           return "My Videos";
//         case "/my-subscription":
//           return "My Subscription"; 
//         default:
//           return "Hedged Investing and Trading for everyone";
//       }
//     }


//   };

//   return (
//     <>
//       <Helmet>
//         <meta charSet="utf-8" />
//         <title>{Title()} | Hedged</title>
//         <link rel="canonical" href={`https://hedged.in${location?.pathname}`} />
//         <meta name="keywords" content={`${Title()} | Hedged`} />
//         <meta property="og:title" content={`${Title()} | Hedged`} />
//         <meta property="og:type" content="website" />
//         <meta
//           property="og:url"
//           content={`https://hedged.in${location?.pathname}`}
//         />
//         <meta property="og:image" content="https://hedged.in/icon-hedge.png" />
//       </Helmet>
//       <header className="comp-header">
//         <div className="container">
//           {/* <a className="logo" href="/inbox">
//             <img
//               src="/assets/images/logo.svg"
//               width="158"
//               height="32"
//               alt="Logo of Hedged"
//             />
//           </a> */}

// {(isMobileToWeb || getFromStorage("mobiletoweb"))? 
//           <div className="logo">
//  <img
//           src="/assets/images/logo.svg"
//           width="158"
//           height="32"
//           alt="Logo of Hedged"
//         />
//             </div>


//           :
//           <a className="logo" href="/inbox">
//             <img
//               src="/assets/images/logo.svg"
//               width="158"
//               height="32"
//               alt="Logo of Hedged"
//             />
//           </a>
// }


//           <div className={`navbar ${classAdd ? "active" : null}`}>
//             <ul className="list">
//               <li className="item">
//                 <a onClick={handleInbox}>Inbox</a>
//               </li>
//               <li className="item">
//                 {/* <a href="/livefunds">Live Funds</a> */}
//                 <a href="/livefunds">'Simply Hedged' Funds</a>
//               </li>
//               <li className="item" style={{ cursor: "pointer" }}>
//                 <a onClick={handleCT}>Hedged TV</a>
//               </li>
//               <li className="item">
//                 <a href="/news">News</a>
//               </li>
//               <li className="item" style={{ cursor: "pointer" }}>
//                 <a onClick={subscrptionPage}>Subscription</a>
//               </li>

//               <li className="item" style={{ cursor: "pointer" }}>
//                 <a href="/about-us">About us </a>
//               </li>
//               {userData?.broker_integration == 1 && (
//                 <li className="item" style={{ cursor: "pointer" }}>
//                   <a href="/positions">My Positions</a>
//                 </li>
//               )}
//             </ul>
//             <span
//               className="icon icon-close"
//               onClick={() => setClassAdd(false)}
//             ></span>
//           </div>

//           <a
//             href="/hedged-result"
//             className="btn typ-small btn-spl hedged-result"
//           >
//             Hedged Results
//           </a>

//           {/* <div className="user-dropdown active" onClick={handleExpand}> */}
//           <div
//             className={`user-dropdown ${expand ? "active" : ""}`}
//             onClick={() => {
//               handleExpand();
//             }}
//           >
//             <div className="user-details">
//               {/* {username?.planid === 1 ? (
//                 ""
//               ) : ( */}
//               {username?.planid == 1 ? (
//                 <span className="icon icon-free user-icon"></span>
//               ) : username?.planid == 2 ? (
//                 <span className="icon icon-prime-new user-icon"></span>
//               ) : username?.planid == 3 ? (
//                 <span className="icon crown user-icon">
//                   <img src="/assets/images/crown.svg" alt="" />
//                 </span>
//               ) : username?.planid == 5 ? (
//                 <span className="icon icon-2hr-trade user-icon"></span>
//               ) : (
//                 <></>
//               )}
//               {/* <span className="icon crown">
//                   <img src="/assets/images/crown.svg" alt="" />
//                 </span> */}
//               {/* )} */}
//               <span
//                 className="username"
//                 style={{ textTransform: "capitalize" }}
//               >
//                 {/* {username?.name} */}
//                 {initials}
//               </span>
//               {(isMobileToWeb || getFromStorage("mobiletoweb")) ? <></> :
//             <>
//                 <span
//                 className="icon user-setting result-icon"
//                 title="Hedged Results"
//                 onClick={hadleHedgedResult}
//               >
//                 <img src="/assets/images/result-icon.png" alt="img" />
//               </span>
//               <span className="icon user-setting">
//                 <img src="/assets/images/manage-account.svg" alt="img" />
//               </span>
//             </>
//               }

//               {/* <span
//                 className="icon user-setting result-icon"
//                 title="Hedged Results"
//                 onClick={hadleHedgedResult}
//               >
//                 <img src="/assets/images/result-icon.png" alt="img" />
//               </span>
//               <span className="icon user-setting">
//                 <img src="/assets/images/manage-account.svg" alt="img" />
//               </span> */}
//             </div>
//             <div className="dropdown">
//               <ul className="list" style={{ textTransform: "capitalize" }}>
//                 <li className="item">
//                   <div className="name-initial-wrap">
//                     <span
//                       className="user-full-name"
//                       style={{ textTransform: "capitalize" }}
//                     >
//                       {username?.name}
//                     </span>
//                     <span className="initial-name">{initials}</span>
//                   </div>
//                 </li>
//                 <li className="item">
//                   <a href="/user-edit-profile">
//                     <span className="icon icon-edit"></span>
//                     <span className="text">Edit Profile</span>
//                   </a>
//                 </li>
//                 <li className="item">
//                   <a href="/manage-track-list">
//                     <span className="icon icon-manage-track-list"></span>
//                     <span className="text">Manage Track list</span>
//                   </a>
//                 </li>
//                 <li className="item">
//                   <a href="/my-videos">
//                     <span className="icon icon-videos"></span>
//                     <span className="text">My Videos</span>
//                   </a>
//                 </li>
//                 {userData?.broker_integration == 1 && (
//                   <>
//                     <li className="item typ-add-broker">
//                       <button
//                         type="buttom"
//                         className="broker-btn"
//                         onClick={handleOpenModel}
//                       >
//                         <span className="icon icon-close"></span>
//                         <span className="title">Add Broker</span>
//                       </button>
//                     </li>
//                     {brokerUserList?.length > 0 && (
//                       <li className="item typ-add-broker">
//                         <a href="#">
//                           <span className="icon icon-tracker"></span>
//                           <span className="title">My Broker</span>
//                         </a>
//                         <ul className="list-broker">
//                           {brokerUserList?.map((item, index) => {
//                             return (
//                               <li className="li-item">
//                                 <div className="my-broker-wrap">
//                                   {/* <div> */}

//                                   <div className="img-info-wrap">
//                                     <div className="img-wrap">
//                                       {item?.broker == "zerodha" ? (
//                                         <img
//                                           src="/assets/images/teaser.png"
//                                           alt=""
//                                         />
//                                       ) : (
//                                         (item?.broker == "paytmMoney" || item?.broker == "paytm") ? 
//                                         <img
//                                           src="/assets/images/paytm-logo.png"
//                                           alt=""
//                                         />
//                                         :
//                                         item?.broker == "dhan" ?
//                                         <img src="/assets/images/dhan.png" alt="dhan"/> :
//                                         item?.broker == "bigul" ?  
//                                         <img src="/assets/images/bigul.png" alt="bigul"/>
//                                         :
//                                         <img
//                                           src="/assets/images/share-khan.png"
//                                           alt=""
//                                         />
//                                       )}
//                                     </div>

//                                     <div className="info-wrap">
//                                       <p className="name">{item?.name}</p>
//                                       <p className="valid-wrap">
//                                         <span
//                                           className={`session-text ${
//                                             isExpired(item?.expiry)
//                                               ? "typ-expired"
//                                               : ""
//                                           }`}
//                                         >
//                                           {isExpired(item?.expiry)
//                                             ? "Session Expired on"
//                                             : "Session valid until"}
//                                         </span>
//                                         <span className="date-time">
//                                           {`${moment(item?.expiry).format(
//                                             "DD/MM/YYYY"
//                                           )} | ${moment(item?.expiry).format(
//                                             "HH:mm:ss"
//                                           )}`}
//                                           {/* 20/08/2023 | 22:51:00 */}
//                                         </span>
//                                       </p>
//                                       {item?.isAvailable &&

//                                   <button
//                                       className="btn re-login-btn"
//                                       onClick={()=> handleBrokerLogin(item)}
//                                     >
//                                       Re-Login
//                                     </button>
//                          }
//                                       {item?.isAvailable && (item?.broker == "zerodha" || item?.broker == "dhan") &&
//                                       <p className="auth-btn" onClick={() => item?.broker == "zerodha" ? handleAuthrization(item?.broker) : handleDhanAuthrization(item?.broker)}>
//                                         CDSL Authorization</p>}
//                                     </div>

//                                   </div>

//                                   {/* </div> */}

//                                   {isExpired(item?.expiry) ? (
//                                     <button
//                                       className="btn"
//                                       onClick={()=> handleBrokerLogin(item)}
//                                     >
//                                       Login
//                                     </button>
//                                   ) : (
//                                     <button
//                                       className="btn-user-delete"
//                                       onClick={() => handleDeRegister(item)}
//                                     >
//                                       <span className="icon icon-close"></span>
//                                     </button>
//                                   )}
//                                 </div>
//                               </li>
//                             );
//                           })}
//                           {/* <li className="li-item">
//                       <div className="my-broker-wrap">
//                         <div className="img-info-wrap">
//                           <div className="img-wrap">
//                             <img src="assets/images/dhan.png" alt="" />
//                           </div>
//                           <div className="info-wrap">
//                             <p className="name">Vishwakumar Shetty</p>
//                             <p className="valid-wrap">
//                               <span className="session-text typ-expired">
//                                 Session Expired on{" "}
//                               </span>
//                               <span className="date-time">
//                                 20/08/2023 | 22:51:00
//                               </span>
//                             </p>
//                           </div>
//                         </div>
//                         <button className="btn btn-brdr">
//                           <span className="btn-inner">
//                             <span className="text">Login</span>
//                           </span>
//                         </button>
//                       </div>
//                     </li> */}
//                         </ul>
//                       </li>
//                     )}
//                   </>
//                 )}
//                 <li className="item">
//                   <a href="/my-subscription">
//                     <span className="icon icon-subscription"></span>
//                     <span className="text">
//                       Subscription
//                       <span className="member-plan">
//                         {username?.planid === 3 ? (
//                           <>
//                             <span className="tag crown">
//                               <span className="icon icon-profit"></span>
//                               <span className="text">Crown</span>
//                             </span>
//                             {/*  <span className="expiry">Expires 25/02/2023</span>*/}
//                           </>
//                         ) : null}
//                       </span>
//                     </span>
//                   </a>
//                 </li>
//                 <li className="item">
//                   <a onClick={handleLogout}>
//                     <span className="icon icon-logout"></span>
//                     <span className="text">logout</span>
//                   </a>
//                 </li>
//               </ul>
//               <a
//                 href="/hedged-result"
//                 className="btn typ-small btn-spl hedged-result"
//               >
//                 Hedged Results
//               </a>
//               <span
//                 className="icon icon-close"
//                 onClick={() => {
//                   handleExpand();
//                 }}
//               ></span>
//             </div>
//           </div>
//           {/* <button
//             className="notify-btn search-btn"
//             onClick={() => searchDrawerClick()}
//           >
//             <span className="icon icon-search"></span>
//           </button> */}
//           <button className="notify-btn" onClick={() => currentUpdateClick()}>
//             <span className="icon icon-notification-alert"></span>
//           </button>
//           <span className="menu-btn" onClick={handleMenu}>
//             <span className="icon icon-menu"></span>
//           </span>
//         </div>
//       </header>
//       <Drawer anchor={"right"} open={currentUpdateDrawer}>
//         <div className="comp-drawer">
//           <div className="drawer-head">
//             <h2 className="title">Notification</h2>
//             <span
//               className="icon icon-close"
//               onClick={currentUpdateClick}
//             ></span>
//           </div>
//           <div className="drawer-cont">
//             <CustomScrollbars className="container">
//               <div className="sec-cont">
//                 <div className="comp-tabs typ-2">
//                   <Tabs
//                     value={value}
//                     onChange={handleChange}
//                     aria-label="basic tabs example"
//                     style={{ marginBottom: "15px" }}
//                   >
//                     <Tab label="Hedged Ideas" {...a11yProps(0)} />
//                     <Tab label="'Simply hedged' Funds" {...a11yProps(1)} />
//                     <Tab label="Market Data" {...a11yProps(2)} />
//                   </Tabs>
//                   <TabPanel value={value} index={0}>
//                     <div className="comp-timeline typ-notification">
//                       <ul className="list">
//                         {notiFicationList &&
//                           notiFicationList?.hedgedIdeas?.map((item, id) => (
//                             <li className="item" id={item?.id}>
//                               <div className="date-time">{item?.createdAt}</div>
//                               <div className="trade-info">
//                                 <p className="label">{item?.title} </p>
//                                 <p className="desc">{item?.content}</p>
//                               </div>
//                             </li>
//                           ))}
//                       </ul>
//                     </div>
//                   </TabPanel>
//                   <TabPanel value={value} index={1}>
//                     <div className="comp-timeline typ-notification">
//                       <ul className="list">
//                         {notiFicationList &&
//                           notiFicationList?.liveFunds?.map((item, id) => (
//                             <li
//                               className="item"
//                               id={item?.id}
//                               // onClick={() => handleRedirect(item)}
//                             >
//                               <div className="date-time">{item?.createdAt}</div>
//                               <div className="trade-info">
//                                 <p className="label">{item?.title} </p>
//                                 <p className="desc">{item?.content}</p>
//                               </div>
//                             </li>
//                           ))}
//                       </ul>
//                     </div>
//                   </TabPanel>
//                   <TabPanel value={value} index={2}>
//                     <div className="comp-timeline typ-notification">
//                       <ul className="list">
//                         {notiFicationList &&
//                           notiFicationList?.others?.map((item, id) => (
//                             <li className="item" id={item?.id}>
//                               <div className="date-time">{item?.createdAt}</div>
//                               <div className="trade-info">
//                                 <p className="label">{item?.title} </p>
//                                 <p className="desc">{item?.content}</p>
//                               </div>
//                             </li>
//                           ))}
//                       </ul>
//                     </div>
//                   </TabPanel>
//                 </div>
//               </div>
//             </CustomScrollbars>
//           </div>
//         </div>
//       </Drawer>
//       {/* search drawer */}
//       <Drawer
//         className="search-drawer"
//         anchor={"right"}
//         open={searchDrawerOpen}
//       >
//         <div className="comp-drawer typ-search-drawer">
//           <div className="drawer-head">
//             <span
//               className="icon icon-close"
//               onClick={searchDrawerClick}
//             ></span>
//           </div>
//           <div className="drawer-cont">
//             <div className="search-wrapper">
//               <div className="search-input-wrap">
//                 <input type="text" placeholder="Search for a stock..." />
//                 <span className="icon icon-search"></span>
//               </div>
//             </div>
//             <div className="recent-search-wrap">
//               <div className="title-cta-wrap">
//                 <h3 className="title">Recent Search</h3>
//                 {/* <button className="btn-view-all">View all</button> */}
//               </div>
//               <ul className="list-wrap">
//                 <li className="item">
//                   <a href="#">Nifty Bank lorem ispum </a>
//                 </li>
//                 <li className="item">
//                   <a href="#">Nifty Bank lorem ispum </a>
//                 </li>
//                 <li className="item">
//                   <a href="#">Nifty Bank lorem ispum dolor amet </a>
//                 </li>
//                 <li className="item">
//                   <a href="#">Nifty Bank lorem ispum </a>
//                 </li>
//                 <li className="item">
//                   <a href="#">Nifty Bank lorem ispum dolor amet </a>
//                 </li>
//                 <li className="item">
//                   <a href="#">Nifty Bank lorem ispum </a>
//                 </li>
//               </ul>
//             </div>
//             <div className="trending-search-wrap">
//               <div className="title-cta-wrap">
//                 <h3 className="title">Trending Search</h3>
//                 {/* <button className="btn-view-all">View all</button> */}
//               </div>
//               <ul className="list-wrap">
//                 <li className="item">
//                   <div className="img-info-wrap">
//                     <div className="img-wrap">
//                       <img
//                         src="assets/images/trendsearch1.png"
//                         alt="trending search image"
//                       />
//                     </div>
//                     <div className="info-wrap">
//                       <h4 className="title">Lorem Iipsum </h4>
//                       <p className="info">
//                         Lorem Ipsum color amet siet dolor lipsum dipsuLorem
//                         Ipsum color amet siet dolor lipsum dipsumm
//                       </p>
//                       <div className="time-wrap">
//                         <span className="text">The Mint</span>
//                         <span className="seperator">|</span>
//                         <span className="text">5 hrs ago</span>
//                       </div>
//                     </div>
//                   </div>
//                   <a href="#" className="trending-link"></a>
//                 </li>
//                 <li className="item">
//                   <div className="img-info-wrap">
//                     <div className="img-wrap">
//                       <img
//                         src="assets/images/trendsearch2.png"
//                         alt="trending search image"
//                       />
//                     </div>
//                     <div className="info-wrap">
//                       <h4 className="title">Lorem Iipsum </h4>
//                       <p className="info">
//                         Lorem Ipsum color amet siet dolor lipsum dipsuLorem
//                         Ipsum color amet siet dolor lipsum dipsumm
//                       </p>
//                       <div className="time-wrap">
//                         <span className="text">The Mint</span> |
//                         <span className="text">5 hrs ago</span>
//                       </div>
//                     </div>
//                   </div>
//                   <a href="#" className="trending-link"></a>
//                 </li>
//                 <li className="item">
//                   <div className="img-info-wrap">
//                     <div className="img-wrap">
//                       <img
//                         src="assets/images/trendsearch1.png"
//                         alt="trending search image"
//                       />
//                     </div>
//                     <div className="info-wrap">
//                       <h4 className="title">Lorem Iipsum </h4>
//                       <p className="info">
//                         Lorem Ipsum color amet siet dolor lipsum dipsuLorem
//                         Ipsum color amet siet dolor lipsum dipsumm
//                       </p>
//                       <div className="time-wrap">
//                         <span className="text">The Mint</span> |
//                         <span className="text">5 hrs ago</span>
//                       </div>
//                     </div>
//                   </div>
//                   <a href="#" className="trending-link"></a>
//                 </li>
//                 <li className="item">
//                   <div className="img-info-wrap">
//                     <div className="img-wrap">
//                       <img
//                         src="assets/images/trendsearch2.png"
//                         alt="trending search image"
//                       />
//                     </div>
//                     <div className="info-wrap">
//                       <h4 className="title">Lorem Iipsum </h4>
//                       <p className="info">
//                         Lorem Ipsum color amet siet dolor lipsum dipsuLorem
//                         Ipsum color amet siet dolor lipsum dipsumm
//                       </p>
//                       <div className="time-wrap">
//                         <span className="text">The Mint</span> |
//                         <span className="text">5 hrs ago</span>
//                       </div>
//                     </div>
//                   </div>
//                   <a href="#" className="trending-link"></a>
//                 </li>
//                 <li className="item">
//                   <div className="img-info-wrap">
//                     <div className="img-wrap">
//                       <img
//                         src="assets/images/trendsearch1.png"
//                         alt="trending search image"
//                       />
//                     </div>
//                     <div className="info-wrap">
//                       <h4 className="title">Lorem Iipsum </h4>
//                       <p className="info">
//                         Lorem Ipsum color amet siet dolor lipsum dipsuLorem
//                         Ipsum color amet siet dolor lipsum dipsumm
//                       </p>
//                       <div className="time-wrap">
//                         <span className="text">The Mint</span> |
//                         <span className="text">5 hrs ago</span>
//                       </div>
//                     </div>
//                   </div>
//                   <a href="#" className="trending-link"></a>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>
//       </Drawer>
//     </>
//   );
// };

// export default Header;

import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Scrollbars } from "react-custom-scrollbars";
import { Link, useHistory } from "react-router-dom";
import { Nav, NavLink, NavMenu, NavBtn, NavBtnLink } from "reactstrap";
import { deleteFromStorage, saveToStorage } from "../../../utils/storage";
import { getFromStorage } from "../../../utils/storage";
import { googleLogout } from "@react-oauth/google";
import API from "../../../utils/APIs";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  captureEvent,
  constants,
  userLogoutCleverTap,
} from "../../../utils/helper";
import moment from "moment/moment";
import { useBrokerLoginStore } from "../../../store/Authentication/broker";
import { useCallData } from "../../../store/Calls/call";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom/cjs/react-router-dom";


// import logo from '../logo/bg-siteLogo.svg';
import { useMobileToWeb } from '../../../store/Positions/mobileToWeb';
import { paths } from "../../../utils/apiEndpoints";
import { useVerificationStore } from "../../../store/Authentication/VerificationStore";

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const [brokerList, setBorkerList] = useState([]);
  const [checkUserData, setCheckUserData] = useState(null);
  // document.title = "Hedged | Header";
  const username = getFromStorage("userDetails");
  const userDetails = getFromStorage("userDetails");
  const setOpenBrokerModel = useBrokerLoginStore(
    (state) => state.setOpenBroker
  );
  const setBrokerUserList = useBrokerLoginStore(
    (state) => state.setBrokerUserList
  );
  // const setShowCityModal = useVerificationStore(
  //   (state) => state.setShowCityModal
  // );

  const brokerUserList = useBrokerLoginStore((state) => state.brokerUserList);
  const userData = useBrokerLoginStore((state) => state.userData);
  const setUserDetails = useBrokerLoginStore((state) => state.setUserData);
  const setOpenConfirmModel = useBrokerLoginStore(
    (state) => state.setOpenConfirmModel
  );
  const isMobileToWeb = useMobileToWeb(
    (state) => state.isMobileToWeb
  );

  const handleLogout = () => {
    deleteFromStorage("token");
    deleteFromStorage("userDetails");
    deleteFromStorage("fromPaytmMoney");
    deleteFromStorage("activeLtps");
    history.push("/login");
    // window.location.reload();
    googleLogout();
    userLogoutCleverTap("logout");
    captureEvent("clicked logout");
  };

  const [currentUpdateDrawer, setCurrentUpdate] = useState(false);
  const [searchDrawerOpen, setSearchDrawer] = useState(false);
  const [value, setValue] = useState(0);
  const [notiFicationList, setNotiFicationList] = useState([]);
  const [expand, setExpand] = useState(false);
  // const [openConfirmModel, setConfirmModel]=useState(false)
  const callData = useCallData((state) => state.callData);

  const currentUpdateClick = () => {
    if (currentUpdateDrawer == false) {
      setCurrentUpdate(true);
      captureEvent('clicked on bell icon')
      getNotification();
      // currentUpdate(id);
    } else {
      setCurrentUpdate(false);
    }
  };
  const searchDrawerClick = () => {
    if (searchDrawerOpen == false) {
      setSearchDrawer(true);
    } else {
      setSearchDrawer(false);
    }
  };

  const getNotification = () => {
    API.notificationList()
      .then((response) => {
        setNotiFicationList(response?.data?.data);
      })
      .catch((err) => { });
  };

  const getBroker = () => {
    fetch(paths.getBrokers, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userDetails?.accessToken,
        "Content-Type": "application/json",
      }),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        setBorkerList(obj?.body);
        setBrokerUserList(obj?.body);
        if (obj?.body?.length > 0) {
          const isBigul = obj?.body?.filter((elem) => elem?.broker == "bigul")
          if (isBigul?.length > 0 && isBigul[0]?.isAvailable == false) {
            deleteFromStorage("token");
            deleteFromStorage("userDetails");
            // history?.push("/login")
            window.location.replace('/login');
          }
        }
        //  console.log("obj", obj);
      })
      .catch((e) => { });
  };

  // useEffect(()=>{
  //   if(brokerUserList?.length > 0){
  //     const isBigul= brokerUserList?.filter((elem)=> elem?.broker == "bigul")
  //     console.log("isBigul", isBigul);
  //     if(isBigul?.length > 0 && isBigul[0]?.isAvailable == false){
  //       deleteFromStorage("token");
  //       deleteFromStorage("userDetails");
  //       // history?.push("/login")
  //       window.location.replace('/login');
  //     }
  //   }
  // },[brokerUserList])
  const setOpenPersonalDetailModel = useBrokerLoginStore(
    (state) => state.setOpenPersonalDetailModel
  );
  const setShowCityModal = useVerificationStore(
    (state) => state.setShowCityModal
  );

  const userProfile = () => {
    API.getUSerProfile()
      .then((response) => {
        // setUserData(response?.data?.data);
        setUserDetails(response?.data?.data);
        // if (response?.data?.data?.broker_email == null && response?.data?.data?.authenticationPlatform == "paytmMoney") {
        //   setOpenPersonalDetailModel(true)
        // }
        checkCityEmptyField(response?.data?.data)

        if (
          response?.data?.data &&
          response?.data?.data?.broker_integration == 1
        ) {
          getBroker();
        }
      })
      .catch((err) => {
        // setError(true);
      });
  };

  const handleExpand = () => {
    setExpand(!expand);
  };

  const handleMetaTags = () => {
    let canonicalLink = document?.querySelector('link[rel="canonical"]');

    if (!canonicalLink) {
      canonicalLink = document.createElement("link");
      canonicalLink.rel = "canonical";
      document.head.appendChild(canonicalLink);
    }

    // Set the canonical URL dynamically
    const canonicalURL = `https://hedged.in${window?.location?.pathname}`;
    canonicalLink.href = canonicalURL;
    let dynamicDescription;

    const currentPath = window?.location?.pathname;
    // Define a default title
    // let dynamiCcanonicalURL;

    // Check if the URL path starts with "/call-details/view/"
    if (currentPath?.startsWith("/call-details/")) {
      dynamicDescription = "Details";
    } else {
      switch (location?.pathname) {
        case "/inbox":
          dynamicDescription =
            "Access Trade updates & alerts from Hedged directly in your inbox. Stay informed and up-to-date about our various Live funds as well.";
          break;
        case "/livefunds":
          dynamicDescription =
            "Invest in unique Live FundsTM, with built-in hedges &amp; set of 3-7 stocks for medium-term. Manage them in just 5 mins a month! Stay informed with Hedged.";
          break;
        case "/hedgedTVList":
          dynamicDescription =
            "Tired of the clutter on Youtube! Watch expert market analysis, trading strategies, and more on our very own OTT HedgedTV.";
          break;
        case "/news":
          dynamicDescription =
            "Stay up-to-date with the latest updates in the stock market with Hedged. Get expert insights and analysis on market trends, company announcements, and more.";
          break;
        case "/subscription":
          dynamicDescription =
            "Hedged offers different subscription plans to suit everyone's Pocket ! Start with as low as 599/- per month and learn the Logic behind our trades.";
          break;
        default:
          dynamicDescription = "Hedged Investing and Trading for everyone";
          break;
      }
    }

    const descriptionMetaTag = document?.querySelector(
      'meta[name="description"]'
    );
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute("content", dynamicDescription);
    }

    const descriptionPropertyTag = document?.querySelector(
      'meta[property="og:description"]'
    );
    if (descriptionPropertyTag) {
      descriptionPropertyTag.setAttribute("content", dynamicDescription);
    }
  }

  useEffect(() => {
    userProfile();
    handleMetaTags()
  }, []);

  class CustomScrollbars extends React.Component {
    render() {
      return (
        <Scrollbars
          className="scrollbar-container"
          renderTrackVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{ ...style, width: '10px' }}
              className="scrollbar-vertical test-1"
            />
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{ ...style }}
              className="thumb-vertical"
            />
          )}
          renderView={({ style, ...props }) => (
            <div
              {...props}
              style={{ ...style }}
              className="view"
            />
          )}
        >
          {this.props.children}
        </Scrollbars>
      );
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const [classAdd, setClassAdd] = useState(false);
  const handleMenu = () => {
    setClassAdd(true);
  };

  const handleCT = () => {
    history.push("/hedgedTVList");
    captureEvent("Clicked on Hedged TV");
  };

  const subscrptionPage = () => {
    history.push("/subscription");
    captureEvent("Subscription Page Viewed");
  };
  
  const spadePage = () => {
    history.push("/aos");
    captureEvent("Ace of spades Page Viewed");
  };

  const handleInbox = () => {
    history.push("/inbox");
    captureEvent("clicked to navigate to Inbox screen");
  };

  const hadleHedgedResult = () => {
    captureEvent('clicked on hedged result')
    history.push("/hedged-result");
  };

  const handleOpenModel = () => {
    setOpenBrokerModel(true);
  };

  const currentDate = new Date();
  const isExpired = (expiryDateTime) => {
    const expiryDate = new Date(expiryDateTime);
    return currentDate > expiryDate;
  };

  const generate_login_url = (name) => {
    let loginUrl;
    switch (name) {
      case "zerodha":
        loginUrl = paths.zerodhaGenerateLogin;
        break;
      case "paytmMoney":
        loginUrl = paths.paytmGenerateLogin;
        break;
      case "dhan":
        loginUrl = paths.dhanGenerateLogin;
        break;
      case "5paisa":
        loginUrl = paths.fivepaisaGenerateLogin;
        break;
      case "upstox":
        loginUrl = paths.upstoxGenerateLogin;
        break;
      default:
        loginUrl = paths.paytmGenerateLogin;
        break;
    }

    return loginUrl;
  }

  const activate_authrisation = (name) => {
    let loginUrl;
    switch (name) {
      case "zerodha":
        loginUrl = paths.zerodhaAuthorizeTpin;
        break;
      case "dhan":
        loginUrl = paths.dhanAuthorizeTpin;
        break;
      default:
        loginUrl = ""
        break;
    }

    return loginUrl;
  }


  const brokerLogin = async (item) => {
    // fetch(`${constants?.broker_base_Url}${generate_login_url(item?.broker)}`, {
    fetch(generate_login_url(item?.broker), {
      method: item?.broker =="dhan" ? "post" :  "get",
      headers: new Headers({
        Authorization: "Bearer " + userDetails?.accessToken,
        "Content-Type": "application/json",
      }),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        //  console.log("obj", obj);
        saveToStorage("prevPath", location?.pathname)
        window.open(obj?.body?.loginUrl ? obj?.body?.loginUrl : obj?.body?.redirectTo, "_self");
        // localStorage.setItem("prevPath", location?.pathname)
      })
      .catch((e) => { });
  };

  const checkCityEmptyField = async (respData) => {
    // fetch(`${constants?.broker_base_Url}${generate_login_url(item?.broker)}`, {
    fetch(`${constants?.baseUrl}api/configtab`, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userDetails?.accessToken,
        "Content-Type": "application/json",
      }),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        //  console.log("obj", obj);
        // localStorage.setItem("prevPath", location?.pathname)
        const checkUser = obj?.body
        const checkDate = new Date(checkUser?.data[0]?.val);
        const createdDate = new Date(respData?.createdAt);

        // console.log("checkUser",checkUser);
        if((respData?.city == "" || respData?.city == null) && createdDate > checkDate && respData?.planid == 1){
          setShowCityModal(true)
        }
        // setCheckUserData()
      })
      .catch((e) => { });
  };


  const handleBrokerLogin = (item) => {
    brokerLogin(item);
  };


  const handleDeRegister = (item) => {
    setOpenConfirmModel({
      open: true,
      loginBroker: item?.broker,
    });
    // deRegister(item?.broker)
  };

  const name = username?.name?.split(/[ _]/);

  const initials = name?.map((word) => word.charAt(0)?.toUpperCase())?.join("");
  const outputString = initials?.length >= 3 && initials.charAt(0) + initials.charAt(initials?.length - 1);


  const handleAuthrization = (name) => {
    fetch(activate_authrisation(name),
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userDetails?.accessToken,
          "Content-Type": "application/json",
        }),
      }
    )
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        //  console.log("obj", obj);
        if (obj?.status == 200) {
          window.open(obj?.body?.redirect_url, "_blank");
        } else {
          toast.error(obj?.body?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((e) => { });
  }

  const handleDhanAuthrization = (name) => {
    fetch(activate_authrisation(name),
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + userDetails?.accessToken,
          "Content-Type": "application/json",
        }),
      }
    )
      .then((r) =>
        r.text().then((data) => ({ status: r.status, body: data }))
      )
      .then((obj) => {
        //  console.log("obj", obj);
        if (obj?.status == 200) {
          const generatedHtml = obj?.body;
          const blob = new Blob([generatedHtml], { type: "text/html" });
          const url = URL.createObjectURL(blob);
          window.open(url, "_target");
        } else {
          toast.error(obj?.body?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((e) => { });
  }

  const Title = () => {
    const currentPath = window.location.pathname;

    // Define a default title
    let dynamicTitle;

    // Check if the URL path starts with "/call-details/view/"
    if (currentPath.startsWith("/call-details/")) {
      dynamicTitle = "Details";

      return dynamicTitle
    } else {
      switch (location.pathname) {
        case "/inbox":
          return "Inbox";
        case "/livefunds":
          return "Simply Hedged";
        case "/hedgedTVList":
          return "HedgedTV";
        case "/news":
          return "News";
        case "/subscription":
          return "Subscribe Now";
        case "/positions":
          return "My Positions";
        case "/user-edit-profile":
          return "Edit Profile";
        case "/user-edit-profile":
          return "Edit Profile";
        case "manage-track-list":
          return "Manage Track";
        case "/my-videos":
          return "My Videos";
        case "/my-subscription":
          return "My Subscription";
        default:
          return "Hedged Investing and Trading for everyone";
      }
    }


  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Title()} | Hedged</title>
        <link rel="canonical" href={`https://hedged.in${location?.pathname}`} />
        <meta name="keywords" content={`${Title()} | Hedged`} />
        <meta property="og:title" content={`${Title()} | Hedged`} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://hedged.in${location?.pathname}`}
        />
        <meta property="og:image" content="https://hedged.in/icon-hedge.png" />
      </Helmet>
      <header className="comp-header">
        <div className="container">
          {(isMobileToWeb || getFromStorage("mobiletoweb")) ?
            <div className="logo">
              <img
                src="/assets/images/logo.svg"
                width="158"
                height="32"
                alt="Logo of Hedged"
              />
            </div>


            :
            <a className="logo" href={userDetails?.planid !== 6 && username?.planid !== 7 && username?.planid !== 8  ? '/inbox' : '/aos'}>
              <img
                src="/assets/images/logo.svg"
                width="158"
                height="32"
                alt="Logo of Hedged"
              />
            </a>
          }

          <div className={`navbar ${classAdd ? "active" : null}`}>
            <ul className="list">
            {username?.planid !== 6 && username?.planid !== 7 && username?.planid !==8  &&
              <li className="item">
                <a onClick={handleInbox}>Inbox</a>
              </li>}
              <li className="item">
                {/* <a href="/livefunds">Live Funds</a> */}
                <a href="/livefunds">'Simply Hedged' Funds</a>
              </li>
              <li className="item" style={{ cursor: "pointer" }}>
                <a onClick={handleCT}>Hedged TV</a>
              </li>
              <li className="item" >
                <a href="/news" onClick={() => captureEvent('clicked on news')}>News</a>
              </li>
              {userData?.planid !== 6 && userData?.planid !== 7 && userData?.planid !== 8 &&
              <li className="item" style={{ cursor: "pointer" }}>
                <a onClick={subscrptionPage}>Subscription</a>
              </li>}

              <li className="item" style={{ cursor: "pointer" }}>
                <a href="/about-us">About us </a>
              </li>
              {userData?.broker_integration == 1 && (
                <li className="item" style={{ cursor: "pointer" }}>
                  <a href="/positions">My Positions</a>
                </li>
              )}
               {(userData?.planid == 7 || userData?.planid == 6 || userData?.planid == 8) && 
           <li className="item" style={{ cursor: "pointer" }}>
                <a onClick={spadePage}>Ace of Spades</a>
              </li> }
            </ul>
            <span
              className="icon icon-close"
              onClick={() => setClassAdd(false)}
            ></span>
          </div>
          {userData?.planid !== 6 && userData?.planid !== 7 && userData?.planid !== 8 ?
          <a
            href="/hedged-result"
            className="btn typ-small btn-spl hedged-result"
            onClick={() => captureEvent('clicked on hedged result')}
          >
            Hedged Results
          </a>
          : <a  className="typ-small btn-spl hedged-result"></a>
          }
          {/* <div className="user-dropdown active" onClick={handleExpand}> */}
          <div
            className={`user-dropdown ${expand ? "active" : ""}`}
            onClick={() => {
              handleExpand();
            }}
          >
            <div className="user-details">
              {/* {username?.planid === 1 ? (
                ""
              ) : ( */}
              {(isMobileToWeb || getFromStorage("mobiletoweb")) ? <></> : (
                
                 username?.planid == 1 ? (
                  <span className="icon icon-free user-icon"></span>
                ) : username?.planid == 2 ? (
                  <span className="icon icon-prime-new user-icon"></span>
                ) : (username?.planid == 3 || username?.planid == 12) ? (
                  <span className="icon crown user-icon">
                    <img src="/assets/images/crown.svg" alt="" />
                  </span>
                ) : username?.planid == 5 ? (
                  <span className="icon icon-2hr-trade user-icon"></span>
                ): (username?.planid == 6 || username?.planid == 7 || username?.planid == 8) ? (
                  <span className="icon spade user-icon">
                    <img src="/assets/images/spade.svg" alt="" />
                  </span>
                )
                : (
                  <></>
                )
              )}
              {/* <span className="icon crown">
                  <img src="/assets/images/crown.svg" alt="" />
                </span> */}
              {/* )} */}
              <span
                className="username"
                style={{ textTransform: "capitalize" }}
              >
                {/* {username?.name} */}
                {initials?.length >= 3 ? outputString : initials}
              </span>
              {(isMobileToWeb || getFromStorage("mobiletoweb")) ? <></> :
                <>
                  {getFromStorage("viewPaytm") ?
                    <span
                      className="icon user-setting result-icon"
                      title="My Position"
                      onClick={() => history?.push("/positions")}
                    >
                      <img src="/assets/images/outCube.svg" alt="img" />
                    </span>
                    :
                    <span
                      className="icon user-setting result-icon"
                      title="Hedged Results"
                      onClick={hadleHedgedResult}
                    >
                      <img src="/assets/images/result-icon.png" alt="img" />
                    </span>
                  }

                  <span className="icon user-setting">
                    <img src="/assets/images/manage-account.svg" alt="img" />
                  </span>
                </>
              }
            </div>
            <div className="dropdown">
              {(isMobileToWeb || getFromStorage("mobiletoweb")) ?
                <>

                  <ul className="list" style={{ textTransform: "capitalize" }}>
                    <li className="item">
                      <div className="name-initial-wrap">
                        <span
                          className="user-full-name"
                          style={{ textTransform: "capitalize" }}
                        >
                          {username?.name}
                        </span>
                        <span className="initial-name">{initials?.length >= 3 ? outputString : initials}</span>
                      </div>
                    </li>
                    {userData?.broker_integration == 1 && (
                      <>
                        {/* {brokerUserList?.filter((elem)=>elem?.broker !== "bigul")?.length > 0 && */}
                        {userDetails?.planid == 12 || userDetails?.planid == 11  ? <></> :
                          <li className="item typ-add-broker">
                            <button
                              type="buttom"
                              className="broker-btn"
                              onClick={handleOpenModel}
                            >
                              <span className="icon icon-close"></span>
                              <span className="title">Add Broker</span>
                            </button>
                          </li>
                        }
                        {/* } */}
                        {brokerUserList?.length > 0 && (
                          <li className="item typ-add-broker">
                            <a href="#">
                              <span className="icon icon-tracker"></span>
                              <span className="title">My Broker</span>
                            </a>
                            <ul className="list-broker">
                              {brokerUserList?.map((item, index) => {
                                return (
                                  <li className="li-item">
                                    <div className="my-broker-wrap">
                                      <div className="img-info-wrap">
                                        <div className="img-wrap">
                                          <img
                                            src={`https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/brokerLogo/${item?.broker}.png`}
                                            alt=""
                                          />
                                          {/* {item?.broker == "zerodha" ? (
                                            <img
                                              src="/assets/images/teaser.png"
                                              alt=""
                                            />
                                          ) : (
                                            (item?.broker == "paytmMoney" || item?.broker == "paytm") ?
                                              <img
                                                src="/assets/images/paytm-logo.png"
                                                alt=""
                                              />
                                              :
                                              item?.broker == "dhan" ?
                                                <img src="/assets/images/dhan.png" alt="dhan" /> :
                                                item?.broker == "bigul" ?
                                                  <img src="/assets/images/bigul.png" alt="bigul" />
                                                  : item?.broker == '5paisa' ?
                                                    <img
                                                      src="/assets/images/5paise.png"
                                                      alt=""
                                                    />
                                                    : item?.broker == "upstox" ?
                                                      <img src="/assets/images/upstox.png" />
                                                      :
                                                      <img
                                                        src="/assets/images/share-khan.png"
                                                        alt=""
                                                      />
                                          )} */}
                                        </div>

                                        <div className="info-wrap">
                                          <p className="name">{item?.name}</p>
                                          <p className="valid-wrap">
                                            <span
                                              className={`session-text ${isExpired(item?.expiry)
                                                ? "typ-expired"
                                                : ""
                                                }`}
                                            >
                                              {isExpired(item?.expiry)
                                                ? "Session Expired on"
                                                : "Session valid until"}
                                            </span>
                                            <span className="date-time">
                                              {`${moment(item?.expiry).format(
                                                "DD/MM/YYYY"
                                              )} | ${moment(item?.expiry).format(
                                                "HH:mm:ss"
                                              )}`}
                                            </span>
                                          </p>
                                          {item?.broker == "bigul" ? <></> : item?.isAvailable &&
                                            <p className="btn re-login-btn" onClick={() => handleBrokerLogin(item)}>
                                              Re-Login </p>}
                                          {item?.isAvailable && (item?.broker == "zerodha" || item?.broker == "dhan") &&
                                            <p className="auth-btn" onClick={() => item?.broker == "zerodha" ? handleAuthrization(item?.broker) : handleDhanAuthrization(item?.broker)}>
                                              CDSL Authorization</p>}
                                        </div>
                                      </div>
                                      {isExpired(item?.expiry) ? (
                                        <button
                                          className="btn"
                                          onClick={() => handleBrokerLogin(item)}
                                        >
                                          Login
                                        </button>
                                      ) : (
                                        <button
                                          className="btn-user-delete"
                                          onClick={() => handleDeRegister(item)}
                                        >
                                          <span className="icon icon-close"></span>
                                        </button>
                                      )}
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        )}
                      </>
                    )}
                    {/* <li className="item">
                      <a href="/positions">
                        <span className="icon icon-manage-track-list"></span>
                        <span className="text">My Positions</span>
                      </a>
                    </li> */}
                  </ul>

                </>

                :

                <>

                  <ul className="list" style={{ textTransform: "capitalize" }}>
                    <li className="item">
                      <div className="name-initial-wrap">
                        <span
                          className="user-full-name"
                          style={{ textTransform: "capitalize" }}
                        >
                          {username?.name}
                        </span>
                        <span className="initial-name">{initials?.length >= 3 ? outputString : initials}</span>
                      </div>
                    </li>
                    <li className="item">
                      <a href="/user-edit-profile">
                        <span className="icon icon-edit"></span>
                        <span className="text">Edit Profile</span>
                      </a>
                    </li>
                    <li className="item">
                      <a href="/aos">
                        <span className="icon"><img src="/assets/images/spade.svg" className='spade-img' alt="" /></span>
                        <span className="text">Ace of spades</span>
                      </a>
                    </li>
                    <li className="item">
                      <a href="/manage-track-list">
                        <span className="icon icon-manage-track-list"></span>
                        <span className="text">Manage Track list</span>
                      </a>
                    </li>
                    <li className="item">
                      <a href="/my-videos">
                        <span className="icon icon-videos"></span>
                        <span className="text">My Videos</span>
                      </a>
                    </li>
                    {userData?.broker_integration == 1 && (
                      <>
                        {/* {brokerUserList?.filter((elem)=>elem?.broker !== "bigul")?.length > 0 && */}
                        {userDetails?.planid == 12 || userDetails?.planid == 11 || userData?.authenticationPlatform == "bigul"  ? <></> :
                          <li className="item typ-add-broker">
                            <button
                              type="buttom"
                              className="broker-btn"
                              onClick={handleOpenModel}
                            >
                              <span className="icon icon-close"></span>
                              <span className="title">Add Broker</span>
                            </button>
                          </li>
                        }
                        {/* } */}
                        {brokerUserList?.length > 0 && (
                          <li className="item typ-add-broker">
                            <a href="#">
                              <span className="icon icon-tracker"></span>
                              <span className="title">My Broker</span>
                            </a>
                            <ul className="list-broker">
                              {brokerUserList?.map((item, index) => {
                                return (
                                  <li className="li-item">
                                    <div className="my-broker-wrap">
                                      <div className="img-info-wrap">
                                        <div className="img-wrap">
                                          <img
                                            src={`https://hedged-prod-assets-01.s3.ap-south-1.amazonaws.com/brokerLogo/${item?.broker}.png`}
                                            alt=""
                                          />
                                          {/* {item?.broker == "zerodha" ? (
                                            <img
                                              src="/assets/images/teaser.png"
                                              alt=""
                                            />
                                          ) : (
                                            (item?.broker == "paytmMoney" || item?.broker == "paytm") ?
                                              <img
                                                src="/assets/images/paytm-logo.png"
                                                alt=""
                                              />
                                              :
                                              item?.broker == "dhan" ?
                                                <img src="/assets/images/dhan.png" alt="dhan" /> :
                                                item?.broker == "bigul" ?
                                                  <img src="/assets/images/bigul.png" alt="bigul" />
                                                  : item?.broker == "upstox" ?
                                                    <img src="/assets/images/upstox-logo.png" />
                                                    :
                                                    <img
                                                      src="/assets/images/share-khan.png"
                                                      alt=""
                                                    />
                                          )} */}
                                        </div>

                                        <div className="info-wrap">
                                          <p className="name">{item?.name}</p>
                                          <p className="valid-wrap">
                                            <span
                                              className={`session-text ${isExpired(item?.expiry)
                                                ? "typ-expired"
                                                : ""
                                                }`}
                                            >
                                              {isExpired(item?.expiry)
                                                ? "Session Expired on"
                                                : "Session valid until"}
                                            </span>
                                            <span className="date-time">
                                              {`${moment(item?.expiry).format(
                                                "DD/MM/YYYY"
                                              )} | ${moment(item?.expiry).format(
                                                "HH:mm:ss"
                                              )}`}
                                              {/* 20/08/2023 | 22:51:00 */}
                                            </span>


                                          </p>
                                          {item?.broker == "bigul" ? <></> :  item?.isAvailable &&
                                            <p className="btn re-login-btn" onClick={() => handleBrokerLogin(item)}>
                                              Re-Login </p>}
                                          {item?.isAvailable && (item?.broker == "zerodha" || item?.broker == "dhan") &&
                                            <p className="auth-btn" onClick={() => item?.broker == "zerodha" ? handleAuthrization(item?.broker) : handleDhanAuthrization(item?.broker)}>
                                              CDSL Authorization</p>}
                                        </div>
                                      </div>
                                      {isExpired(item?.expiry) ? (
                                        <button
                                          className="btn"
                                          onClick={() => handleBrokerLogin(item)}
                                        >
                                          Login
                                        </button>
                                      ) : (
                                        <button
                                          className="btn-user-delete"
                                          onClick={() => handleDeRegister(item)}
                                        >
                                          <span className="icon icon-close"></span>
                                        </button>
                                      )}
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </li>
                        )}
                      </>
                    )}
                    {username?.planid !== 11 && userData?.authenticationPlatform !== "bigul" &&
                    <li className="item">
                      <a href="/my-subscription">
                        <span className="icon icon-subscription"></span>
                        <span className="text">
                          Subscription
                          <span className="member-plan">
                            {(username?.planid === 3 || userDetails?.planid == 12) ? (
                              <>
                                <span className="tag crown">
                                  <span className="icon icon-profit"></span>
                                  <span className="text">Crown</span>
                                </span>
                                {/*  <span className="expiry">Expires 25/02/2023</span>*/}
                              </>
                            ) : (username?.planid === 6 || userDetails?.planid == 7 || username?.planid === 8)  ? 
                            <>
                                <span className="tag 2HR Trader">
                                  {/* <span className="icon icon-profit"></span> */}
                                  <span className="text">AOS</span>
                                </span>
                                {/*  <span className="expiry">Expires 25/02/2023</span>*/}
                              </>
                             :null}
                          </span>
                        </span>
                      </a>
                    </li>}
                    <li className="item">
                      <a onClick={handleLogout}>
                        <span className="icon icon-logout"></span>
                        <span className="text">logout</span>
                      </a>
                    </li>
                  </ul>
                  {username?.planid !== 6 && username?.planid !== 7 && username?.planid !== 8 && 
                  <a
                    href="/hedged-result"
                    className="btn typ-small btn-spl hedged-result"
                    onClick={() => captureEvent('clicked on hedged result')}
                  >
                    Hedged Results
                  </a>}
                </>
              }

              <span
                className="icon icon-close"
                onClick={() => {
                  handleExpand();
                }}
              ></span>
            </div>
          </div>
          {/* <button
            className="notify-btn search-btn"
            onClick={() => searchDrawerClick()}
          >
            <span className="icon icon-search"></span>
          </button> */}
          {
            (isMobileToWeb || getFromStorage("mobiletoweb")) ? <></> :
              <button className="notify-btn" onClick={() => currentUpdateClick()}>
                <span className="icon icon-notification-alert"></span>
              </button>
          }
          <span className="menu-btn" onClick={(isMobileToWeb || getFromStorage("mobiletoweb")) ? handleExpand : handleMenu}>
            <span className="icon icon-menu"></span>
          </span>
        </div>
      </header>
      <Drawer anchor={"right"} open={currentUpdateDrawer}>
        <div className="comp-drawer">
          <div className="drawer-head">
            <h2 className="title">Notification</h2>
            <span
              className="icon icon-close"
              onClick={currentUpdateClick}
            ></span>
          </div>
          <div className="drawer-cont">
            <CustomScrollbars className="container">
              <div className="sec-cont">
                <div className="comp-tabs typ-2">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    style={{ marginBottom: "15px" }}
                  >
                    <Tab label="Hedged Ideas" {...a11yProps(0)} />
                    <Tab label="'Simply hedged' Funds" {...a11yProps(1)} />
                    <Tab label="Market Data" {...a11yProps(2)} />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <div className="comp-timeline typ-notification">
                      <ul className="list">
                        {notiFicationList &&
                          notiFicationList?.hedgedIdeas?.map((item, id) => (
                            <li className="item" id={item?.id}>
                              <div className="date-time">{item?.createdAt}</div>
                              <div className="trade-info">
                                <p className="label">{item?.title} </p>
                                <p className="desc">{item?.content}</p>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <div className="comp-timeline typ-notification">
                      <ul className="list">
                        {notiFicationList &&
                          notiFicationList?.liveFunds?.map((item, id) => (
                            <li
                              className="item"
                              id={item?.id}
                            // onClick={() => handleRedirect(item)}
                            >
                              <div className="date-time">{item?.createdAt}</div>
                              <div className="trade-info">
                                <p className="label">{item?.title} </p>
                                <p className="desc">{item?.content}</p>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <div className="comp-timeline typ-notification">
                      <ul className="list">
                        {notiFicationList &&
                          notiFicationList?.others?.map((item, id) => (
                            <li className="item" id={item?.id}>
                              <div className="date-time">{item?.createdAt}</div>
                              <div className="trade-info">
                                <p className="label">{item?.title} </p>
                                <p className="desc">{item?.content}</p>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </TabPanel>
                </div>
              </div>
            </CustomScrollbars>
          </div>
        </div>
      </Drawer>
      {/* search drawer */}
      <Drawer
        className="search-drawer"
        anchor={"right"}
        open={searchDrawerOpen}
      >
        <div className="comp-drawer typ-search-drawer">
          <div className="drawer-head">
            <span
              className="icon icon-close"
              onClick={searchDrawerClick}
            ></span>
          </div>
          <div className="drawer-cont">
            <div className="search-wrapper">
              <div className="search-input-wrap">
                <input type="text" placeholder="Search for a stock..." />
                <span className="icon icon-search"></span>
              </div>
            </div>
            <div className="recent-search-wrap">
              <div className="title-cta-wrap">
                <h3 className="title">Recent Search</h3>
                {/* <button className="btn-view-all">View all</button> */}
              </div>
              <ul className="list-wrap">
                <li className="item">
                  <a href="#">Nifty Bank lorem ispum </a>
                </li>
                <li className="item">
                  <a href="#">Nifty Bank lorem ispum </a>
                </li>
                <li className="item">
                  <a href="#">Nifty Bank lorem ispum dolor amet </a>
                </li>
                <li className="item">
                  <a href="#">Nifty Bank lorem ispum </a>
                </li>
                <li className="item">
                  <a href="#">Nifty Bank lorem ispum dolor amet </a>
                </li>
                <li className="item">
                  <a href="#">Nifty Bank lorem ispum </a>
                </li>
              </ul>
            </div>
            <div className="trending-search-wrap">
              <div className="title-cta-wrap">
                <h3 className="title">Trending Search</h3>
                {/* <button className="btn-view-all">View all</button> */}
              </div>
              <ul className="list-wrap">
                <li className="item">
                  <div className="img-info-wrap">
                    <div className="img-wrap">
                      <img
                        src="assets/images/trendsearch1.png"
                        alt="trending search image"
                      />
                    </div>
                    <div className="info-wrap">
                      <h4 className="title">Lorem Iipsum </h4>
                      <p className="info">
                        Lorem Ipsum color amet siet dolor lipsum dipsuLorem
                        Ipsum color amet siet dolor lipsum dipsumm
                      </p>
                      <div className="time-wrap">
                        <span className="text">The Mint</span>
                        <span className="seperator">|</span>
                        <span className="text">5 hrs ago</span>
                      </div>
                    </div>
                  </div>
                  <a href="#" className="trending-link"></a>
                </li>
                <li className="item">
                  <div className="img-info-wrap">
                    <div className="img-wrap">
                      <img
                        src="assets/images/trendsearch2.png"
                        alt="trending search image"
                      />
                    </div>
                    <div className="info-wrap">
                      <h4 className="title">Lorem Iipsum </h4>
                      <p className="info">
                        Lorem Ipsum color amet siet dolor lipsum dipsuLorem
                        Ipsum color amet siet dolor lipsum dipsumm
                      </p>
                      <div className="time-wrap">
                        <span className="text">The Mint</span> |
                        <span className="text">5 hrs ago</span>
                      </div>
                    </div>
                  </div>
                  <a href="#" className="trending-link"></a>
                </li>
                <li className="item">
                  <div className="img-info-wrap">
                    <div className="img-wrap">
                      <img
                        src="assets/images/trendsearch1.png"
                        alt="trending search image"
                      />
                    </div>
                    <div className="info-wrap">
                      <h4 className="title">Lorem Iipsum </h4>
                      <p className="info">
                        Lorem Ipsum color amet siet dolor lipsum dipsuLorem
                        Ipsum color amet siet dolor lipsum dipsumm
                      </p>
                      <div className="time-wrap">
                        <span className="text">The Mint</span> |
                        <span className="text">5 hrs ago</span>
                      </div>
                    </div>
                  </div>
                  <a href="#" className="trending-link"></a>
                </li>
                <li className="item">
                  <div className="img-info-wrap">
                    <div className="img-wrap">
                      <img
                        src="assets/images/trendsearch2.png"
                        alt="trending search image"
                      />
                    </div>
                    <div className="info-wrap">
                      <h4 className="title">Lorem Iipsum </h4>
                      <p className="info">
                        Lorem Ipsum color amet siet dolor lipsum dipsuLorem
                        Ipsum color amet siet dolor lipsum dipsumm
                      </p>
                      <div className="time-wrap">
                        <span className="text">The Mint</span> |
                        <span className="text">5 hrs ago</span>
                      </div>
                    </div>
                  </div>
                  <a href="#" className="trending-link"></a>
                </li>
                <li className="item">
                  <div className="img-info-wrap">
                    <div className="img-wrap">
                      <img
                        src="assets/images/trendsearch1.png"
                        alt="trending search image"
                      />
                    </div>
                    <div className="info-wrap">
                      <h4 className="title">Lorem Iipsum </h4>
                      <p className="info">
                        Lorem Ipsum color amet siet dolor lipsum dipsuLorem
                        Ipsum color amet siet dolor lipsum dipsumm
                      </p>
                      <div className="time-wrap">
                        <span className="text">The Mint</span> |
                        <span className="text">5 hrs ago</span>
                      </div>
                    </div>
                  </div>
                  <a href="#" className="trending-link"></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Header;
