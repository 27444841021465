import "./App.css";
import React, { Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
// Import Routes all
import { authProtectedRoutes, openRoutes, publicRoutes } from "./routes";
// Import all middleware
import Authmiddleware from "./routes/route";
import { Helmet } from "react-helmet";
// layouts Format
import { QueryClient, QueryClientProvider } from "react-query";
import AuthLayout from "./components/Layout/AuthLayout";
import NonAuthHeader from "./components/Layout/NonAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Page404 from "./pages/Page404";
import clevertap from "clevertap-web-sdk";
import { captureEvent, constants, userLoginFacebook } from "./utils/helper";
import Smartlook from "smartlook-client";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useBrokerLoginStore } from "./store/Authentication/broker";
import BrokerConfirmModel from "./components/Model/BrokerConfirmModel";
import BrokerLogin from "./components/Model/BrokerLogin";
import { useCallData } from "./store/Calls/call";
import { getFromStorage, saveToStorage } from "./utils/storage";
import PaytmRequestModel from "./components/Model/PaytmRequestModel";
import ErrorModel from "./components/Model/ErrorModel";
import PersonalDetails from "./components/Model/PaytmMoney/PersonalDetails";
import OtpModel from "./components/Model/OtpModel";
import CityModal from "./components/Model/CityModal";


const firebaseConfig = {
  apiKey: "AIzaSyCLSCfXVDL-ABfH7hfXrHWRA4lLQOyQRTk",
  authDomain: "hedged-321407.firebaseapp.com",
  projectId: "hedged-321407",
  storageBucket: "hedged-321407.appspot.com",
  messagingSenderId: "921048349840",
  appId: "1:921048349840:web:001d48f52d30eedba86c15",
  measurementId: "G-ZW86Z0ESRQ",
};

// Import the functions you need from the SDKs you need
clevertap.init("675-R9K-R76Z", "eu1");
// clevertap.initialize({ accountId: 'YOUR_ACCOUNT_ID', passcode: 'YOUR_PASSCODE' });
const queryClient = new QueryClient();

function App() {
  // const location = useLocation()
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const openBrokerModel = useBrokerLoginStore((state) => state.openBroker);

  // const userdata = getFromStorage('userDetails');
  // const userData = useBrokerLoginStore((state) => state.userData);
  const currentPrice = getFromStorage("activeLtps");

  // const userUrl = Smartlook.instance.userdata.getUserUrl();

  // function userUrlChangedCallback(userUrl) {
  //   console.log('Emitted user url', userUrl);
  // }

  const setCurrentLtpPrice = useCallData((state) => state.setCurrentLtpPrice);

  const fetchLtp = () => {
    fetch(
      `${constants?.broker_base_Url}instruments/get_active_instruments_ltp`,
      {
        method: "get",
      }
    )
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        if (obj?.body) {
          const hasValue = Object.values(obj?.body).some(value => value !== null);
          if (hasValue) {
            setCurrentLtpPrice(obj?.body);
            saveToStorage("activeLtps", obj?.body);
          }
        }
      })
      .catch((e) => { });
  };

  // useEffect(()=>{
  //   if(userData && !currentPrice){
  //     fetchLtp()
  //   }else if(currentPrice){
  //     setCurrentLtpPrice(currentPrice)
  //   }
  // },[userData])



  useEffect(() => {
    if (!currentPrice) {
      fetchLtp();
    } else if (currentPrice) {
      setCurrentLtpPrice(currentPrice);
    }
    // console.log("id", clevertap.getAccountID());

    // clevertap.notifications.push({
    //   "titleText": "Hello from clevertap team! ",
    //   "bodyText": "Alllow clevertap to push notification"
    // })
    // if (isMarketOpen()) {
    const intervalId = setInterval(fetchLtp, 10000);
    return () => clearInterval(intervalId);
    }, []);

  useEffect(() => {
    // Smartlook.instance.eventListeners.registerUserChangedListener(
    //   userUrlChangedCallback
    // );

    captureEvent("first open");
    Smartlook.record({
      emails: true,
      forms: true,
      numbers: false,
    });

    //smart look url changes track
    //  window.addEventListener('web_track url', () => {
    //    Smartlook.trackUrlChange();
    //  });

    // const originalPushState = window.history.pushState;
    // window.history.pushState = function (state, title, url) {
    //   originalPushState.apply(this, arguments);
    //   Smartlook.trackUrlChange();
    // };

    // const originalReplaceState = window.history.replaceState;
    // window.history.replaceState = function (state, title, url) {
    //   originalReplaceState.apply(this, arguments);
    //   Smartlook.trackUrlChange();
    // };

    // clevertap.recordPageView();
    //clevertap.trackUrlChange();

    // clevertap.event.push('web_Page changed', {
    //   'web_Page name': window?.location?.pathname,
    // });
  }, []);

  //clevertap url changes track

  //  useEffect(() => {
  //   const handleRouteChange = () => {
  //     clevertap.event.push('web_url changed', {
  //       'Page name': window?.location?.pathname,
  //    });
  //  };

  //  window.addEventListener('popstate', handleRouteChange);

  //  return () => {
  //    window.removeEventListener('popstate', handleRouteChange);
  //  };
  // }, [window?.location?.pathname]);

  // analytics url changes track
  //  useEffect(() => {
  //    if (window?.location?.pathname) {
  //      analytics.setCurrentScreen(window?.location?.pathname);
  //   }
  // }, [analytics, window?.location?.pathname]);
  const LoadingFallback = (
    <div style={{ width: "100%", display: "flex", alignItems: 'center', justifyContent: 'center' }}>
      <img style={{ width: '150px' }} src="assets/images/rolling.svg" alt="" />
    </div>
  );


  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hedged Investing and Trading for everyone | Hedged</title>
        <link rel="canonical" href="https://hedged.in/" />
        <meta
          name="keywords"
          content="Hedged Investing and Trading for everyone  | Hedged"
        />
        <meta
          name="description"
          content="Discover Hedged, India's First platform for the 'Hedged style' of Investing and Trading, pushed daily straight to your inbox"
        />
        <meta
          property="og:title"
          content="Hedged Investing and Trading for everyone  | Hedged"
        />
        <meta
          property="og:description"
          content="Discover Hedged, India's First platform for the 'Hedged style' of Investing and Trading, pushed daily straight to your inbox"
        />
        <meta property="og:url" content="https://hedged.in/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://hedged.in/icon-hedge.png" />
      </Helmet>

      <GoogleOAuthProvider clientId="82883995126-2krqrv1g1to6ngce6frmcfu76ch4272d.apps.googleusercontent.com">
        <QueryClientProvider client={queryClient}>
          <Router>
            <Suspense fallback={LoadingFallback}>
              <Switch>
                {publicRoutes?.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={NonAuthHeader}
                    component={route.component}
                    key={idx}
                    isAuthProtected={false}
                    exact
                  />
                ))}
                {authProtectedRoutes?.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={AuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    exact
                  />
                ))}
                {openRoutes?.map((route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={undefined}
                    component={route.component}
                    key={idx}
                    isAuthProtected={false}
                    exact
                  />
                ))}
              </Switch>
            </Suspense>
          </Router>
          <BrokerLogin />
          <BrokerConfirmModel />
          <PaytmRequestModel />
          <ErrorModel />
          <PersonalDetails />
          <OtpModel />
          <CityModal/>
        </QueryClientProvider>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
