import React from "react";
import { useBrokerLoginStore } from "../../store/Authentication/broker";
import { deleteFromStorage, getFromStorage } from "../../utils/storage";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { toast } from "react-toastify";
import { constants } from "../../utils/helper";

const BrokerConfirmModel = () => {
  const userDetails = getFromStorage("userDetails");
  const openConfirmModel = useBrokerLoginStore(
    (state) => state.openConfirmModel
  );
  const broker = useBrokerLoginStore((state) => state.broker);
  const setBrokerUserList = useBrokerLoginStore(
    (state) => state.setBrokerUserList
  );

  const setOpenConfirmModel = useBrokerLoginStore(
    (state) => state.setOpenConfirmModel
  );

  const getBroker = () => {
    fetch(`${constants?.broker_base_Url}user/get_brokers`, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userDetails?.accessToken,
        "Content-Type": "application/json",
      }),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        // setBorkerList(obj?.body);
        setBrokerUserList(obj?.body);
        //  console.log("obj", obj);
      })
      .catch((e) => {});
  };

  const deRegister = async (broker) => {
    fetch(
      `${constants?.broker_base_Url}user/deregister_broker?broker=${broker}`,
      {
        method: "DELETE",
        headers: new Headers({
          Authorization: "Bearer " + userDetails?.accessToken,
          "Content-Type": "application/json",
        }),
      }
    )
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        if (obj?.status == "200") {
          setOpenConfirmModel(false);
          if(broker == "bigul"){
            deleteFromStorage("token");
            deleteFromStorage("userDetails");
            // history?.push("/login")
            window.location.replace('/login');
          }

          toast.success(obj?.body?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          getBroker();
        } else {
          setOpenConfirmModel(false);
          toast.error(obj?.body?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {});
  };

  const handleLogout = () => {
    deRegister(broker);
  };
  return (
    <Dialog
      className="comp-modal typ-login typ-subscribe"
      open={openConfirmModel}
      onClose={() =>
        setOpenConfirmModel({
          open: false,
          broker: "",
        })
      }
    >
      <div className="wrapper-bg">
        <div className="modal-head">
          <h2 className="title">Confirm logout</h2>
          <p className="sub-title">Are you sure you want to logout?</p>
          <div className="cta-wrap">
            <button
              className="btn"
              style={{ marginRight: "10px" }}
              onClick={handleLogout}
            >
              Yes
            </button>
            <button
              className="btn outline-btn"
              style={{}}
              onClick={() =>
                setOpenConfirmModel({
                  open: false,
                  broker: "",
                })
              }
            >
              cancel
            </button>
          </div>
          {/* <span className="close-modal" onClick={handleClose4}>
              <span class="icon-close"></span>
            </span> */}
        </div>
      </div>
    </Dialog>
  );
};

export default BrokerConfirmModel;
