import React, { useState } from "react";
import ReactPlayer from "react-player";
import Dialog from "@mui/material/Dialog";

const Page404 = () => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenVideo = () => {
    setOpenModal(true);
  };

  return (
    <>
      <div className="main pg-landing">
        <div className="section section-1">
          <div className="container">
            <div className="sec-cont d-flex align-item-center justify-content-between">
              <div className="left-wrap">
                <div className="logo-wrap">
                  <img
                    src="assets/images/logo.svg"
                    width="146"
                    height="30"
                    alt="Logo of Hedged"
                  />
                </div>
                <h3 className="small-title">
                  Algorithmic Hedged Trades, Everyday to your Hedged Inbox
                </h3>
                <p className="desc">
                  Powered by a Combination of Algorithms, A.I and Human
                  Expertise
                </p>
                <div className="action-wrap">
                  <a
                    className="btn"
                    href="https://play.google.com/store/apps/details?id=com.hedged"
                    target="_blank"
                  >
                    <span className="icon icon-download"></span> Download App
                  </a>
                  <button className="btn btn-brdr" onClick={handleOpenVideo}>
                    <span className="btn-inner">
                      <span className="icon icon-play-video"></span>
                      <span className="text">How it Works</span>
                    </span>
                  </button>
                </div>
              </div>
              <div className="right-wrap">
                <img
                  src="../assets/images/error-404.png"
                  className="wow fadeInDown"
                  data-wow-offset="2"
                  alt=""
                  width="587"
                  height="415"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        className="comp-modal typ-confirm"
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div>
          <button
            onClick={() => setOpenModal(false)}
            style={{ marginBottom: "10px" }}
          >
            X
          </button>
          <ReactPlayer
            url="https://hedged.in/assets/videos/modal-video.mp4"
            width="100%"
            height="100%"
            controls={true}
          />
        </div>
      </Dialog>
    </>
  );
};

export default Page404;
