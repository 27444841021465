import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import AuthLayout from '../components/Layout/AuthLayout';
import NonAuthLayout from '../components/Layout/NonAuth';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const location = useLocation()
  if (Layout !== undefined) {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (isAuthProtected && !localStorage.getItem('token')) {
            return (
              <Redirect
                to={{ pathname: '/login', state: { from: props.location } }}
              />
            );
          } else if (!isAuthProtected && localStorage.getItem('token')) {
            return (
              <Redirect
                to={{ pathname: '/inbox', state: { from: props.location } }}
              />
            );
          }else if (isAuthProtected && localStorage.getItem('token') && (JSON.parse(localStorage.getItem('userDetails'))?.planid == 6 || JSON.parse(localStorage.getItem('userDetails'))?.planid == 7 || JSON.parse(localStorage.getItem('userDetails'))?.planid == 8) && location?.pathname == "/inbox") {
            return (
              <Redirect
                to={{ pathname: '/aos', state: { from: props.location } }}
              />
            );
          }

          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        }}
      />
    );
  }
  else {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (localStorage.getItem('token')) {
            return (
              <AuthLayout>
                <Component {...props} />
              </AuthLayout>
            );
          } else {
            return (
              <NonAuthLayout>
                <Component {...props} />
              </NonAuthLayout>
            );
          }
        }}
      />
    );
  }
};

export default Authmiddleware;
