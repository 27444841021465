import React, { useState } from 'react'
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useVerificationStore } from '../../store/Authentication/VerificationStore';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, FormFeedback } from "reactstrap";
import API from '../../utils/APIs';
import Autocomplete from "@mui/material/Autocomplete";
import { useBrokerLoginStore } from '../../store/Authentication/broker';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { captureEvent } from '../../utils/helper';




const CityModal = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [cityOptions, setCityOptions] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);
    const [open, setOpen] = useState(false);
    const [showInput, setShowInput] = useState(false)
    const showCityModal = useVerificationStore(
        (state) => state.showCityModal
    );
    const userData = useBrokerLoginStore((state) => state.userData);
    const setShowCityModal = useVerificationStore(
        (state) => state.setShowCityModal
    );
   
    
    const updateProfile = async (data) => {
      setLoading(true)
      API.editProfile(data)
        .then((response) => {
          if (response?.status === 200) {
            setLoading(false)
            setShowCityModal(false)

            toast.success(response?.data?.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
          })
          
          } else {
            setLoading(false)
            toast.error(response?.data?.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
          });
          }
        })
        .catch((err) => {
      setLoading(false)

          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
        
        });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            city: userData?.city ? userData?.city :"",
            tradingCapital:userData?.tradingCapital ? userData?.tradingCapital :"",
            experience: userData?.experience ? userData?.experience :""
        },
        validationSchema: Yup.object({
          city: Yup.string().required("Please select city"),
          tradingCapital: Yup.string().required("Select Trading Capital").nullable(),
          experience: Yup.string().required("Select Trading Level").nullable(),
        }),
        onSubmit: (values) => {

          const getForm = {
            name: userData.name,
            // email: userData.email,
            // phonenumber: userData.mobile,
            // dateofbirth: userData.dateofbirth ? userData.dateofbirth : "",
            gender: userData.gender ? userData.gender : "",
            experience: values.experience,
            newsletter: userData?.newsletter,
            city: values?.city,
            tradingCapital:values?.tradingCapital
          };
          updateProfile(getForm)
          
        },
    });

    const handleClose = () => {
        toast.error("Please add city", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }


  const handleSearch = (e) => {
    const getInputValue = e.target.value;
    // setCityName(e.target.value);
    setOpen(false)

    if (e.target.value?.length >= 3) {
      API.getCityList(e.target.value)
        .then(async (response) => {
          // console.log("response", response);
          const newOptions =
            getInputValue?.trim() !== '' && response?.data.length === 0
              ? ['Others']
              : response?.data;
          setOpen(true)
          setCityOptions(newOptions);

        })
        .catch((err) => { });
    }
  };

  const handleSelect = (event, newValue) => {
    if (newValue == "Others") {
      setShowInput(true)
      setSelectedCity(newValue);

    } else {
      setSelectedCity(newValue);
      formik.setFieldValue("city", newValue);
    }
    setOpen(false)


  }
  const handleInputChange = (event, newInputValue) => {
    // Check if the user has removed the selected value
    if (!newInputValue) {
      setSelectedCity(null);
      formik.setFieldValue("city", "")
      formik.setFieldError("city", "City Not Found")
      // setCityName("")
      setShowInput(false)

    }
  };

  const filterOptions = (options, { inputValue }) => {
    // Return the original list of options without any filtering
    return options;
  };

  const handleTradingCapital = (e) => {

    formik.setFieldValue("tradingCapital", e.target.value);

  };


  const handleTrading = (e) => {
    if (e.target.value === "beginner") {
      captureEvent("newbie as experience selected");
      formik.setFieldValue("experience", "beginner");
    } else {
      captureEvent("Experienced as experience selected");
      formik.setFieldValue("experience", "exprieance");
    }
  };


  return (
        <Dialog
            className="comp-modal"
            open={showCityModal}
        >
            <div className="modal-head">
                <h2 className="title">Enter Details</h2>
                <span className="close-modal" onClick={handleClose}>
                    <span class="icon-close"></span>
                </span>
            </div>
            <DialogContent id="alert-dialog-description">
                <div className="login-form">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            formik.handleSubmit();
                            return false;
                        }}
                        className="form"
                        action=""
                    >
                    <label className="input-label" style={{color:"#898D99",lineHeight:"18px", fontWeight:"400", fontSize:"16px", marginBottom:"10px"}}>
                        City
                      </label>
                         <div className="form-group city-input">
                         {/* <FormLabel id="demo-trading">City</FormLabel> */}
                  <Autocomplete
                    // open={true}
                    style={{marginTop:"8px"}}
                    freeSolo
                    id="city"
                    disableClearable
                    onClose={() => setOpen(false)}
                    options={cityOptions && cityOptions}
                    filterOptions={filterOptions}
                    onChange={handleSelect}
                    value={selectedCity ? selectedCity : formik?.values?.city || ""}
                    // noOptionsText={cityName && cityOptions.length === 0 ? 'No cities found' : null}
                    onInputChange={handleInputChange}
                    open={open}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="city"
                        variant="filled"
                        label="Search City"
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                        onChange={handleSearch}
                        // InputLabelProps={{
                        //   style: { marginBottom: '10px' } // Adjust the margin-bottom as needed
                        // }}
                        onBlur={formik.handleBlur}
                        invalid={
                          formik.touched.city && formik.errors.city
                            ? true
                            : false
                        }
                        validation={formik}
                      />
                    )}
                  />
                  {formik &&
                    formik.touched.city &&
                    formik.errors.city && selectedCity !== "Others" ? (
                    <FormFeedback
                      type="invalid"
                      style={{ color: 'red', marginTop: '10px' }}
                    >
                      {formik.errors.city}
                    </FormFeedback>
                  ) : null}

                </div>
                {showInput &&

                  <div className="form-group">

                    <TextField
                      id="city"
                      label="City"
                      variant="filled"
                      type="text"
                      name="city"
                      autoComplete="off"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.city || ''}
                      invalid={
                        formik.touched.city && formik.errors.city
                          ? true
                          : false
                      }
                      validation={formik}
                    />

                    {formik &&
                      formik.touched.city &&
                      formik.errors.city ? (
                      <FormFeedback
                        type="invalid"
                        style={{ color: 'red', marginTop: '10px' }}
                      >
                        {formik.errors.city}
                      </FormFeedback>
                    ) : null}
                  </div>
                }

                <div className="form-group radio-broup">
                  <FormControl>
                    <FormLabel id="demo-trading">Trading Capital</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-trading"
                      name="tradingCapital"
                      onChange={handleTradingCapital}
                    >
                      <FormControlLabel
                        value="0-50K"
                        control={<Radio />}
                        label="0 - 50K"
                      />
                      <FormControlLabel
                        value="50K-1Lakh"
                        control={<Radio />}
                        label="50K - 1 Lakh"
                      />
                      <FormControlLabel
                        value="1Lakh-2Lakh"
                        control={<Radio />}
                        label="1 Lakh - 2 Lakh"
                      />
                      <FormControlLabel
                        value="2Lakh+"
                        control={<Radio />}
                        label="2 Lakh+"
                      />
                    </RadioGroup>
                  </FormControl>
                  {formik.touched.tradingCapital && formik.errors.tradingCapital ? (
                    <FormFeedback
                      type="invalid"
                      style={{
                        display: "flex",
                        marginTop: "5px",
                        marginBottom: "10px",
                        color: "red",
                      }}
                    >
                      {formik.errors.tradingCapital}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="form-group radio-broup">
                  <FormControl>
                    <FormLabel id="demo-trading">Trading Level</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-trading"
                      name="experience"
                      style={{ flexDirection: "row" }}
                      onChange={handleTrading}
                    >
                     <FormControlLabel
                        value="exprieance"
                        control={<Radio />}
                        label="Experienced"
                      />
                      <FormControlLabel
                        value="beginner"
                        control={<Radio />}
                        label="Beginner"
                      />
                     
                    </RadioGroup>
                  </FormControl>
                  {formik.touched?.experience && formik.errors?.experience ? (
                    <FormFeedback
                      type="invalid"
                      style={{
                        display: "flex",
                        marginTop: "5px",
                        marginBottom: "10px",
                        color: "red",
                      }}
                    >
                      {formik.errors.experience}
                    </FormFeedback>
                  ) : null}
                </div>

                        <div className="act-wrap">
                            <button className="btn" type="submit" autoFocus style={{ background: loading && "#a4b0b9" }}>
                                {" "}
                                Save
                            </button>
                        </div>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default CityModal