import { constants } from "../utils/helper";
import { Checkbox } from '@mui/material/Checkbox';

const paths = {
  getBrokers: constants.broker_base_Url + "user/get_brokers",
  zerodhaGenerateLogin:
    constants.broker_base_Url + "zerodha/zerodha_generate_login_url",
  zerodhaBrokerLogin: constants.broker_base_Url + "zerodha/zerodha_login",
  dhanGenerateLogin: constants.broker_base_Url + "dhan/dhan_generate_consent",
  dhanBrokerLogin: constants.broker_base_Url + "dhan/dhan_login",
  fivepaisaGenerateLogin:
    constants.broker_base_Url + "5paisa/5paisa_generate_login_url",
  fivepaisaBrokerogin: constants.broker_base_Url + "5paisa/5Paisa_login",
  paytmGenerateLogin:
    constants.broker_base_Url + "paytm/paytm_generate_login_url",
  paytmBrokerLogin: constants.broker_base_Url + "paytm/paytm_login",
  upstoxGenerateLogin:
    constants.broker_base_Url + "upstox/upstox_generate_login_url",
  upstoxBrokerLogin: constants.broker_base_Url + "upstox/upstox_login",
  getBigulUser: constants.broker_base_Url + "bigul/get_bigul_user",
  bigulLogin: constants.broker_base_Url + "bigul/bigul_login",
  zerodhaAuthorizeTpin: constants.broker_base_Url + "zerodha/authorize_tpin",
  dhanAuthorizeTpin: constants.broker_base_Url + "dhan/authorize_tpin",
  createPosition: constants.broker_base_Url + "positions/create_position",
  confirmPosition:
    constants.broker_base_Url + "positions/confirm_position_entry",
  retryFailedPosition:
    constants.broker_base_Url + "positions/retry_failed_position",
  markRetryFailedPosition: constants.broker_base_Url + "positions/mark_position_failure",
  markRetryFailedLeg: constants.broker_base_Url + "positions/mark_leg_failure",
  retryFailedLeg: constants.broker_base_Url + "positions/retry_failed_orders",
  exitPosition: constants.broker_base_Url + "positions/exit_position",
  modifyLeg: constants.broker_base_Url + "positions/modify_leg",
  recheckLeg: constants.broker_base_Url + "positions/force_recheck_leg",
  getRebalanceTrade:
    constants.broker_base_Url + "positions/get_rebalance_trades",
  confirmRebalanceTrade:
    constants.broker_base_Url + "positions/confirm_rebalance",
  deletePosition: constants.broker_base_Url + "positions/delete_position",
  livefundCreatePosition: constants.broker_base_Url + "livefunds/create_position",
  livefundConfirmPosition:
    constants.broker_base_Url + "livefunds/confirm_position_entry",
  livefundRetryFailedPosition:
    constants.broker_base_Url + "livefunds/retry_failed_position",
  livefundMarkRetryFailedPosition: constants.broker_base_Url + "livefunds/mark_position_failure",
  livefundMarkRetryFailedLeg: constants.broker_base_Url + "livefunds/mark_leg_failure",
  livefundRetryFailedLeg: constants.broker_base_Url + "livefunds/retry_failed_orders",
  livefundExitPosition: constants.broker_base_Url + "livefunds/exit_position",
  livefundModifyLeg: constants.broker_base_Url + "livefunds/modify_leg",
  livefundRecheckLeg: constants.broker_base_Url + "livefunds/force_recheck_leg",
  livefundGetRebalanceTrade:
    constants.broker_base_Url + "livefunds/get_rebalance_trades",
  livefundConfirmRebalanceTrade:
    constants.broker_base_Url + "livefunds/confirm_rebalance",
  livefundDeletePosition: constants.broker_base_Url + "livefunds/delete_position",
  listOpenPosition: constants.broker_base_Url + "user/list_open_positions",
  listClosePosition: constants.broker_base_Url + "user/list_closed_positions",
  listOpenLivefunds: constants.broker_base_Url + "user/list_open_livefunds",
  listCloseLivefunds: constants.broker_base_Url + "user/list_open_positions",
  getAvailableFund: constants.broker_base_Url + "user/get_available_funds",
  deRegisterBroker: constants.broker_base_Url + "user/deregister_broker",
  errorMessage: constants.baseUrl + 'api/errormesg',
  getPaytmmoneyUser: constants?.broker_base_Url + 'paytm/get_paytmmoney_user',
  checkUser: constants?.baseUrl + 'users/checkuseravailable',
  paytmUserUpdate: constants?.baseUrl + 'user/broker/detail',
  paytmAccessToken: constants?.broker_base_Url + 'paytm/accesstoken_login',
  verifyPaytmUser: constants?.baseUrl + 'users/initiateVerification',
  verifyOtp: constants?.baseUrl + 'users/verifyotp',
  checkUserBroker: constants?.baseUrl + 'users/brokeruserdetail',
  resendOtp: constants?.baseUrl + 'users/resendVerification',
};

const createAPiEndpoint = (apiName, query = '') => {
  let apiPath = paths[apiName];
  return apiPath + query;
};

const createQueryEndpoint = (apiName, pathVars) => {
  const apiPath = paths[apiName];
  const joinQueryParams = pathVars.map((item) => {
    return `${item.pathName}=${item.pathValue}`;
  });

  return `${apiPath}?${joinQueryParams.join('&')}`;
};

const createPositionEndpoint = (name, callId, broker, value, source) => {
  return createQueryEndpoint(name, [
    { pathName: name == 'livefundCreatePosition' ? 'bundleId' : 'callId', pathValue: callId },
    { pathName: 'broker', pathValue: broker },
    { pathName: 'userMultiplier', pathValue: value },
    { pathName: 'source', pathValue: source },
  ]);
};

const allpositionEndpoint = (name, legId, price, type) => {
  return createQueryEndpoint(name, [
    { pathName: 'legId', pathValue: legId },
    { pathName: name == 'modifyLeg' ? 'orderPrice' : 'price', pathValue: price },
    { pathName: 'orderType', pathValue: type },
  ]);
};

const retryFailedEndpoint = (name, positionId, orderType) => {
  return createQueryEndpoint(name, [
    { pathName: 'positionId', pathValue: positionId },
    { pathName: 'orderType', pathValue: orderType },
  ]);
};

const markPositionEndpoint = (name, positionId) => {
  return createQueryEndpoint(name, [
    { pathName: 'positionId', pathValue: positionId },
  ]);
};

const markLegEndpoint = (name, legId) => {
  return createQueryEndpoint(name, [
    { pathName: 'legId', pathValue: legId },
  ]);
};

const apiErrorMessage = (name, code) => {
  return createQueryEndpoint(name, [
    { pathName: 'code', pathValue: code },
  ]);
};

export { createAPiEndpoint, createQueryEndpoint, createPositionEndpoint, retryFailedEndpoint, markPositionEndpoint, markLegEndpoint, allpositionEndpoint, apiErrorMessage, paths }