import React from "react";
import { useBrokerLoginStore } from "../../store/Authentication/broker";
import { getFromStorage, saveToStorage } from "../../utils/storage";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { toast } from "react-toastify";
import { constants } from "../../utils/helper";
import { paths } from "../../utils/apiEndpoints";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ErrorModel = () => {
  // const location = useLocation();
  const openErrorModel = useBrokerLoginStore((state) => state.openErrorModel);
  const selectedBroker = useBrokerLoginStore((state) => state.broker);
  const prevUrl = useBrokerLoginStore((state) => state.url);


  const userDetails = getFromStorage("userDetails");

  const setOpenErrorModel = useBrokerLoginStore(
    (state) => state.setOpenErrorModel
  );


  const generate_login_url = (name) => {
    let loginUrl;
    switch (name) {
      case "zerodha":
        loginUrl =
          "zerodha/zerodha_generate_login_url";
        break;
      case "paytmMoney":
        loginUrl =
          "paytm/paytm_generate_login_url";
        break;
      case "dhan":
        loginUrl =
          "dhan/dhan_generate_consent";
        break;
      case "5paisa":
        loginUrl =
          "5paisa/5paisa_generate_login_url";
        break;
      case "upstox":
        loginUrl =
          "upstox/upstox_generate_login_url";
        break
      default:
        loginUrl = "paytm/paytm_generate_login_url";
        break;
    }

    return loginUrl;
  }


  const brokerLogin = async () => {
    fetch(`${constants?.broker_base_Url}${generate_login_url(selectedBroker)}`, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userDetails?.accessToken,
        "Content-Type": "application/json",
      }),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        //  console.log("obj", obj);
        window.open(obj?.body?.loginUrl ? obj?.body?.loginUrl : obj?.body?.redirectTo, "_self");
        // localStorage.setItem("prevPath", location?.pathname)
        saveToStorage("prevPath", prevUrl)
      })
      .catch((e) => { });
  };



  return (
    <Dialog
      className="comp-modal typ-login typ-subscribe"
      open={openErrorModel}
      onClose={() =>
        setOpenErrorModel({
          open: false,
          broker: selectedBroker,
          prevUrl: prevUrl
        })
      }
    >
      <div className="wrapper-bg">
        <div className="modal-head">
          <h2 className="title">Please login to continue</h2>
          <p className="sub-title">
            Please ensure that you have your broker account connected to Hedged to place orders.
          </p>
          <div className="cta-wrap">

            <button
              className="btn outline-btn"
              style={{ marginRight: "10px" }}
              onClick={brokerLogin}
            >
              Login
            </button>
            <button
              className="btn"
              onClick={() =>
                setOpenErrorModel({
                  open: false,
                  broker: selectedBroker,
                  prevUrl: prevUrl
                })
              }
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ErrorModel;
