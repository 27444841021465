import React, { useEffect, useState } from "react";
import API from "../../utils/APIs";
import { getFromStorage, saveToStorage } from "../../utils/storage";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useBrokerLoginStore } from "../../store/Authentication/broker";
import { CircularProgress } from "@mui/material";
import { constants } from "../../utils/helper";
import { useMobileToWeb } from "../../store/Positions/mobileToWeb";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const BrokerLogin = () => {
  const userdetails = getFromStorage("userDetails");
  const [loading, setLoading] = useState(true);
  const [borkerList, setBrokerList] = useState([]);
  const setOpenBrokerModel = useBrokerLoginStore(
    (state) => state.setOpenBroker
  );
  const openBrokerModel = useBrokerLoginStore((state) => state.openBroker);
  const setbrokerList = useBrokerLoginStore((state) => state.setBrokerList);
  const userData = useBrokerLoginStore((state) => state.userData);
  const setOpenRequestModel = useBrokerLoginStore(
    (state) => state.setOpenRequestModel
  );
  const isMobileToWeb = useMobileToWeb(
    (state) => state.isMobileToWeb
  );


  const handleClose = () => {
    setOpenBrokerModel(false);
  };

  const getBrokers = () => {
    API.broker()
      .then((response) => {
        // setlistLoading(false);

        // setBrokerList(response?.data?.data);
        //   setbrokerList(response?.data?.data);
        //   setLoading(false);
        if (
          userData?.default_broker == "" ||
          userData?.default_broker == null
        ) {
          setBrokerList(response?.data?.data);
          setbrokerList(response?.data?.data);
          setLoading(false);
        } else {
          const filterData = response?.data?.data?.filter(
            (elem) => elem?.label == userData?.default_broker
          );
          setBrokerList(filterData);
          setbrokerList(filterData);
          setLoading(false);
        }
      })
      .catch((err) => {});
  };


  useEffect(() => {
    if (openBrokerModel) {
      getBrokers();
    }
  }, [openBrokerModel]);

  const zerodhaLogin = async () => {
    fetch(`${constants?.broker_base_Url}zerodha/zerodha_generate_login_url`, {
      method: "get",
      headers: new Headers({
        Authorization: "Bearer " + userdetails?.accessToken,
        "Content-Type": "application/json",
      }),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        window.open(obj?.body?.loginUrl, "_self");
        saveToStorage("prevPath", window.location.pathname)
      })
      .catch((e) => {});
  };

  const handleZerodhaLogin = (item) => {
    zerodhaLogin();
  };

  

  const [showMore, setShowMore] = useState(false);

  // const handleSlide = () => {
  //   setShowMore(!showMore);
  // };

  const handleClick =()=>{
    setOpenBrokerModel(false)
    setOpenRequestModel(true)
  }

const generate_login_url = (name)=>{
  let loginUrl;
  switch (name) {
    case "Zerodha":
      loginUrl =
        "zerodha/zerodha_generate_login_url";
        break;
    case "PayTM Money":
      loginUrl =
        "paytm/paytm_generate_login_url";
        break;
    case "Dhan":
      loginUrl =
        "dhan/dhan_generate_consent";
      break;
      case "5 Paise":
      loginUrl =
        "5paisa/5paisa_generate_login_url";
      break;
      case "upstox":
        loginUrl =
          "upstox/upstox_generate_login_url";
        break;
    default:
      loginUrl="/";
      break;
  }

  return loginUrl;
}



  const brokersLogin = async (name) => {
    fetch(`${constants?.broker_base_Url}${generate_login_url(name)}`, {
      method: name =="Dhan" ? "post" : "get",
      headers: new Headers({
        Authorization: "Bearer " + userdetails?.accessToken,
        "Content-Type": "application/json",
      }),
    })
      .then((r) => r.json().then((data) => ({ status: r.status, body: data })))
      .then((obj) => {
        //  console.log("obj", obj);
        saveToStorage("prevPath", window.location.pathname)
        window.open(obj?.body?.redirectTo ? obj?.body?.redirectTo : obj?.body?.loginUrl, "_self");
      })
      .catch((e) => {});
  };

  const handleBrokersLogin = (item) => {
    brokersLogin(item?.label);
  };

  const handleLogin =(item)=>{
    if(item?.label == "Zerodha"){
      handleZerodhaLogin(item)
    }else{
      handleBrokersLogin(item)
    }
  }

  return (
    <Dialog
      className="comp-modal typ-login"
      open={openBrokerModel}
      onClose={(isMobileToWeb || getFromStorage('mobiletoweb') )? undefined : handleClose}
    >
      <div className="wrapper-bg">
        <div className="modal-head">
          <h2 className="title">Login with your broker</h2>
          <p className="sub-title">
          Connect with your broker account to place orders
          </p>
          {(isMobileToWeb || getFromStorage('mobiletoweb') ) ? <></>:
          <span className="close-modal" onClick={handleClose}>
            <span class="icon-close"></span>
          </span>
}
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="broker-wrap">
                <ul className={`broker-list ${showMore ? "active" : ""}`}>
                  {borkerList?.length > 0 &&
                    borkerList?.map((item, index) => {
                      return ((item?.label == "Zerodha" || item?.label == "PayTM Money" || item?.label == "Patym" || item?.label == "upstox" || item?.label == "Dhan") &&
                        <li
                          className={`item ${
                            item?.label == "Zerodha" ||  item?.label == "PayTM Money" || item?.label == "Patym" || item?.label == "upstox" || item?.label == "Dhan"  ? "" : "typ-overlay"
                          }`}
                          key={index}
                        >
                          <div
                            className="broker-card"
                            onClick={()=> handleLogin(item)}
                          >
                            <div className="broker-logo">
                              <img src={item?.img_url} alt={item?.label} />
                            </div>
                            <p className="broker-name">{item?.label}</p>
                          </div>
                          <div className="backdrop"></div>
                          {(item?.label == "Zerodha" || item?.label == "PayTM Money" || item?.label == "Patym" || item?.label == "upstox" || item?.label == "Dhan") ? 
                          <></>
                          :(
                            <div className="overlay">
                              <span className="text">
                                <span className="cm-line-break">Coming</span>
                                Soon
                              </span>
                            </div>
                          )}
                          {/* <a href="#" className="broker-link"></a> */}
                        </li>
                      );
                    })}
                </ul>
                {/* {borkerList?.length > 6 && (
                  <button className="btn-show-more" onClick={handleSlide}>
                    {showMore ? "Hide" : "View More brokers"}
                  </button>
                )} */}
                 
                  <button className="btn-show-more" onClick={handleClick}>
                    {/* {showMore ? "Hide" : "View More brokers"} */}
                    Open a PayTM account and Get Prime membership for FREE
                  </button>
               
              </div>
            </DialogContentText>
          </DialogContent>
        )}
      </div>
    </Dialog>
  );
};

export default BrokerLogin;
