import { create } from "zustand";

export const useVerificationStore = create((set, get) => ({
    showModal: false,
    showCityModal:false,

    setShowCityModal:(payload)=>{
        set((state) => ({
            showCityModal: payload,
        }));
    },
    setShowModal: (payload) => {
        set((state) => ({
            showModal: payload,
        }));
    },
    setShowCityModal: (payload) => {
        set((state) => ({
            showCityModal: payload,
        }));
    },
}));
