import AxiosInst from "./axios-utils";
import AxiosInstwithoutauth from "./axioswithoutauth-utils";

const API = {};

// |-------------------------Auth APIs ------------------------------|

API.checkuserAvailable = async (data) =>
  AxiosInstwithoutauth.post("users/checkuseravailable", {
    username: data?.username,
  });

API.initiateVerification = async (data) =>
  AxiosInstwithoutauth.post("users/initiateVerification", data);
API.verifyUser = async (data) =>
  AxiosInstwithoutauth.post("users/verifyUser", data);

API.signup = async (data) => AxiosInstwithoutauth.post("users/register", data);

API.getCityList = async (city) => AxiosInstwithoutauth.get(`getcity?city=${city}`);
// API.getAccessToken = async (data) =>
//   AxiosInstwithoutauth.post('users/register', data);
API.updateTradingExp = async (data) =>
  AxiosInst.put("api/updateExperience", data);

API.forgotPassword = async (data) =>
  AxiosInstwithoutauth.post("users/initiateVerification", {
    username: data.mobile,
    type: "forgot-password",
    authenticationPlatform: "app",
  });

API.otpApi = async (data) => AxiosInstwithoutauth.post("users/verifyotp", data);
API.VerifyOTP = async (data) =>
  AxiosInstwithoutauth.post("users/verifyotp", {
    username: data.phone,
    type: "forgot-password",
    verification_code: data.otp,
  });

API.resetPassword = async (data) =>
  AxiosInstwithoutauth.post("users/forgotpassword", data);

API.notificationApi = async (data) =>
  AxiosInst.put("api/usersinfo/notificationToken", data);
// |-------------------------Auth APIs ends------------------------------|

// |-------------------------Account APIs ------------------------------|

API.getUSerProfile = async () => AxiosInst.get("api/userDetails");
API.editProfile = async (data) => AxiosInst.patch("api/userDetails", data);
API.manageTrackingList = async () => AxiosInst.get("api/tracking");

API.manageTrackingLiveFundList = async () =>
  AxiosInst.get("api/tracklivefunds");
API.subscriptionStatus = async () => AxiosInst.get("api/subscription/status");
API.notificationList = async () => AxiosInst.get("api/notifications/list");

// |-------------------------Account APIs ends ------------------------------|

// |-------------------------Hedgometere APIs starts ------------------------------|
API.hedgeoMeterValue = async () => AxiosInst.get("api/hedgeometervalues");
API.hedgeoMeter = async () => AxiosInst.get("api/hedgeometer");
API.hedgeoMeterActive = async () => AxiosInst.get("api/hedgeometer?active=1");
// |-------------------------Hedgometere APIs ends ------------------------------|

// |-------------------------call APIs start ------------------------------|
API.inboxCall = async (data) => AxiosInst.get("/api/calls", data);
API.broker = async () => AxiosInst.get("/api/calls/getBrokerList");

API.intradayCall = async (status) =>
  AxiosInst.get(`/api/intraday?status=${status}`);
API.singleIntraCall = async (id) => AxiosInst.get(`/api/intraday?id=${id}`);

API.IntradayCallTrack = async (id) => AxiosInst.put("api/intraday/user", id);

API.getCall = async (plan, status) =>
  AxiosInst.get(`api/calls/free?status=${status}&trades=${plan}`);
API.singleCall = async (id) => AxiosInst.get(`/api/calls/single?id=${id}`);
API.singleLiveFundCall = async (id) =>
  AxiosInst.get(`/api/tracklivefunds/livefundlist?id=${id}`);

API.freeHedgedResult = async (planid, ytd) =>
  AxiosInst.get(`api/calls/hedgedresults?planid=${planid}&ytd=${ytd}`);
API.primeHedgedResult = async (planid, ytd) =>
  AxiosInst.get(`/api/calls/hedgedresults?planid=${planid}&ytd=${ytd}`);
API.crownHedgedResult = async (planid, ytd) =>
  AxiosInst.get(`api/calls/hedgedresults?planid=${planid}&ytd=${ytd}`);
API.HedgedResultData = async (id, ytd, pageno, pageSize) =>
  AxiosInst.get(
    `api/calls/hedgedresults?planid=${id}&ytd=${ytd}&pageno=${pageno}&limit=${pageSize}`
  );

API.IntradayHedgedResultData = async (ytd, pageno, pageSize) =>
  AxiosInst.get(
    `api/intraday/hedgedresults?ytd=${ytd}&pageno=${pageno}&limit=${pageSize}`
  );

API.IntradayHedgedResult = async (data) =>
  AxiosInst.get("api/intraday/hedgedresults?ytd=1");
API.hrTraderResult = async (planid, ytd) =>
  AxiosInst.get(`api/calls/hedgedresults?planid=${planid}&ytd=${ytd}`);

API.IntradayTradeUpdates = async (id) =>
  AxiosInst.get(`api/intraday/${id}/history`);

API.currentUpdateApi = async (id) => AxiosInst.get(`api/calls/${id}/history`);
API.tradeLogicApi = async (id) => AxiosInst.get(`api/calls/${id}/tradelogic`);
API.bonusPicks = async (data) => AxiosInst.get("api/calls/bonus");

// |-------------------------call APIs ends ------------------------------|

// |-------------------------News APIs start ------------------------------|
API.relatedNews = async (symbol) => AxiosInst.get(`api/news?s=${symbol}`);
API.getBussinessNews = async () => AxiosInst.get("api/news");

// |-------------------------News APIs start ------------------------------|

API.watchByYou = async () => AxiosInst.get("api/watchbyyou");
API.addwatchByYou = async (id) =>
  AxiosInst.post("api/watchbyyou", {
    videoid: id.toString(),
  });
API.video = async () => AxiosInst.get("api/videos");

// |-------------------------News APIs ends ------------------------------|

// |-------------------------Broker APIs start ------------------------------|

// API.getbrokerLoginUrl = async () =>
//   AxiosInst.get("zerodha/zerodha_generate_login_url");

// |-------------------------Broker APIs end ------------------------------|

API.getPlans = async () => AxiosInst.get("api/plans");
API.getSubscriptionTransactions = async () =>
  AxiosInst.get("api/subscription/billings");

API.trackingTrade = async (data) => AxiosInst.post("api/tracking", data);
API.trackingLiveFundTrade = async (data) =>
  AxiosInst.post("api/tracklivefunds", data);

API.deleteTracking = async (data) =>
  AxiosInst.delete("api/tracking", {
    data: data,
  });

API.deleteLiveFundTracking = async (data) =>
  AxiosInst.delete("api/tracklivefunds/livefundlist", {
    data: data,
  });

API.liveFundInbox = async () => AxiosInst.get("api/bundles");
API.liveFundDetails = async (id) =>
  AxiosInst.get(`api/bundles?childtables=calls&childtables=history&id=${id}`);

// |------------------------- Payment start ------------------------------|
// API.subscriptionCreate = async (data) =>
//   AxiosInst.post('api/subscription/create', data);

// API.checkOut = async (id) =>
//   AxiosInst.get(`api/checkout/paymenttypes?subscriptionid=${id}`);

// API.checkOutPay = async (data) => AxiosInst.post(`api/checkout/pay?webReq=true`, data);

API.subscriptionCreate = async (data) =>
  AxiosInst.post("api/subscription/pay?webReq=true", data);
API.checkOut = async (data) => AxiosInst.post(`api/checkout/website/pay`, data);

API.getPaymentStatus = async (id) =>
  AxiosInst.get(`payment/response?cf_id=${id}`);

API.checkPaymentStatus = async (subId, subscriptionId, type, orderId, userId) =>
  AxiosInst.get(
    `payment/response-sdk?order_id=${subId}&subscriptionid=${subscriptionId}&type=${type}&cf_id=${orderId}&user_id=${userId}`
  );

// |------------------------- Payment end ------------------------------|

// |------------------------- 2HR Trader Started ------------------------------|
API.submitRequestCallBackForm = async (data) =>
  AxiosInst.post("api/requestcallback", data);
// |------------------------- 2HR Trader Ended ------------------------------|

// |------------------------- Paytm Order Integration Started ------------------------------|
API.submitRequestPaytm = async (data) =>
  AxiosInst.post("api/requestpaytm", data);
// |------------------------- Paytm Order Integration Ended ------------------------------|

export default API;
