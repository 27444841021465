import { create } from "zustand";

export const useCallData = create((set, get) => ({
  callsArray: false,
  currentLtpPrice:null,

  setCallsArray: (payload) => {
    set((state) => ({
      callsArray: payload,
    }));
  },

  setCurrentLtpPrice: (payload) => {
    set((state) => ({
      currentLtpPrice: payload,
    }));
  },

 
}));
