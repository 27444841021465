import React, { useState } from 'react'
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useVerificationStore } from '../../store/Authentication/VerificationStore';
import { useHistory } from 'react-router-dom';
import { createAPiEndpoint } from '../../utils/apiEndpoints';
import { getFromStorage } from '../../utils/storage';
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, FormFeedback } from "reactstrap";
import { useBrokerLoginStore } from '../../store/Authentication/broker';


const OtpModel = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const showModal = useVerificationStore(
        (state) => state.showModal
    );

    const setShowModal = useVerificationStore(
        (state) => state.setShowModal
    );
    const setOpenPersonalDetailModel = useBrokerLoginStore(
        (state) => state.setOpenPersonalDetailModel
    );

    const personalDetailsData = useBrokerLoginStore(
        (state) => state.personalDetailsData
    );

    const handleVerificartion = () => {
        setLoading(true)
        fetch(createAPiEndpoint('paytmUserUpdate', `?id=${getFromStorage("userDetails")?.id}`), {
            method: "post",
            headers: new Headers({
                Authorization: "Basic " + btoa(`appuser@injin.com:N_w?Z92w%-BuLvKX`),
                "Content-Type": "application/json",
            }),
            body: JSON?.stringify(personalDetailsData),
        })
            .then((r) => r.json().then((resp) => ({ status: r.status, body: resp })))
            .then((obj) => {
                setLoading(false)
                if (obj?.status == 200) {
                    setShowModal(false);
                    if (getFromStorage("mobiletoweb")) {
                        window.ReactNativeWebView.postMessage(JSON.stringify({ redirectTo: 'app' }));
                    } else {
                        history?.push("/inbox");
                    }
                    setOpenPersonalDetailModel(false);
                    toast.success(obj?.body?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                } else {
                    toast.error(obj?.body?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }).catch((err) => {
                setLoading(false)
                toast.error("Error Occurred", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
    }

    const verifyUser = (otp) => {
        fetch(createAPiEndpoint('verifyOtp'), {
            method: "post",
            headers: new Headers({
                Authorization: "Basic " + btoa(`appuser@injin.com:N_w?Z92w%-BuLvKX`),
                "Content-Type": "application/json",
            }),
            body: JSON?.stringify({
                phonenumber: personalDetailsData?.phonenumber,
                verification_code: otp.toString(),
                type: 'broker-verification'
            }),
        })
            .then((r) => r.json().then((resp) => ({ status: r.status, body: resp })))
            .then((obj) => {
                if (obj?.status === 200) {
                    formik.resetForm();
                    handleVerificartion();
                    // setShowModal(false);
                } else {
                    toast.error(obj?.body?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => {
                toast.error(error?.response?.data?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            otp: "",
        },
        validationSchema: Yup.object({
            otp: Yup.string().required("Enter OTP"),
        }),
        onSubmit: (values) => {
            formik.resetForm();
            verifyUser(values?.otp);
        },
    });

    const handleClose = () => {
        toast.error("Please verifed your details to proceed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    const resendOtpToUser = () => {
        setLoading(true)
        const payloadData = {
            "phonenumber": personalDetailsData?.phonenumber,
            "type": "broker-verification",
            "email": personalDetailsData?.email,
            "authenticationPlatform": "broker"
        }
        fetch(createAPiEndpoint('resendOtp'), {
            method: "post",
            headers: new Headers({
                Authorization: "Basic " + btoa(`appuser@injin.com:N_w?Z92w%-BuLvKX`),
                "Content-Type": "application/json",
            }),
            body: JSON?.stringify(payloadData),
        })
            .then((r) => r.json().then((resp) => ({ status: r.status, body: resp })))
            .then((obj) => {
                setLoading(false)
                if (obj?.status == 200) {
                    toast.success(obj?.body?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });

                } else {
                    setLoading(false)
                    toast.error(obj?.body?.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }).catch((err) => {
                console.log(err);
                setLoading(false)
                toast.error("Error Occurred", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
    };



    return (
        <Dialog
            className="comp-modal"
            open={showModal}
        >
            <div className="modal-head">
                <h2 className="title">Enter OTP</h2>
                <span className="close-modal" onClick={handleClose}>
                    <span class="icon-close"></span>
                </span>
            </div>
            <DialogContent id="alert-dialog-description">
                <div className="login-form">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            formik.handleSubmit();
                            return false;
                        }}
                        className="form"
                        action=""
                    >
                        <div className="form-group">
                            <TextField
                                label="Enter OTP"
                                variant="filled"
                                id="otp"
                                name="otp"
                                type="otp"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.otp || ""}
                                invalid={
                                    formik.touched.otp && formik.errors.otp ? true : false
                                }
                                formik={formik}
                            />

                            {formik.touched.otp && formik.errors.otp ? (
                                <FormFeedback type="invalid" style={{ color: "red" }}>
                                    {formik.errors.otp}
                                </FormFeedback>
                            ) : null}
                            <div style={{ textAlign: "right", margin: "3px 3px 0 0" }}><span onClick={resendOtpToUser} style={{ fontSize: "14px", textDecoration: "underline", color: "#fff", cursor: "pointer" }}>Resend OTP</span></div>
                        </div>

                        <div className="act-wrap">
                            <button className="btn" type="submit" autoFocus style={{ background: loading && "#a4b0b9" }}>
                                {" "}
                                Submit OTP
                            </button>
                        </div>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default OtpModel