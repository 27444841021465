import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useBrokerLoginStore } from "../../store/Authentication/broker";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import { Form, FormFeedback } from "reactstrap";
import { toast } from "react-toastify";
import API from "../../utils/APIs";

const PaytmRequestModel = () => {
  const openRequestModel = useBrokerLoginStore(
    (state) => state.openRequestModel
  );

  const setOpenRequestModel = useBrokerLoginStore(
    (state) => state.setOpenRequestModel
  );
  const userData = useBrokerLoginStore((state) => state.userData);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const sendRequest = (data) => {
    API.submitRequestPaytm(data)
      .then((response) => {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error Occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .finally(() => {
        setOpenRequestModel(false);
        validation.resetForm();
      });
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      telegramId: "",
      email: userData?.email,
      mobile: userData?.phonenumber,
    },
    validationSchema: Yup.object({
      telegramId: Yup.string().required("Please Enter Telegram Id"),
      email: Yup.string()
        .email("Email is Invalid")
        .required("Please Enter Your Email"),
      mobile: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Please write a valid contact number")
        .max(10, "too long")
        .required("Please Enter Your Mobile"),
    }),
    onSubmit: (values) => {
      // captureEvent("Clicked on Signup");
      const getForm = {
        email: values.email,
        phonenumber: values.mobile,
        telegramid: values.telegramId,
        status: "Open",
        remark: "Pending",
      };
      sendRequest(getForm);
    },
  });

  return (
    <Dialog
      className="comp-modal typ-request"
      open={openRequestModel}
      onClose={() => setOpenRequestModel(false)}
    >
      <div className="wrapper-bg">
        <div className="modal-head">
          <h2 className="title">PayTM Money Demat Account</h2>
          <span className="close-modal" onClick={() => setOpenRequestModel(false)}>
            <span class="icon-close"></span>
          </span>
        </div>
        <DialogContent className="dialog-content">
          <DialogContentText id="alert-dialog-description">
            <div className="modelBody">
              <div className="step-wrap">
                <ul className="step-list">
                  <li className="step-item">
                    <p className="step-mess">
                      <span className="step-count">Step 1 :</span> Use this url
                      to download PayTM Money App in your mobile device{" "}
                      <a href="https://paytmmoney.onelink.me/9L59/qvlsg1bg" className="download-link">
                      https://paytmmoney.onelink.me/9L59/qvlsg1bg
                      </a>
                    </p>
                  </li>
                  <li className="step-item">
                    <p className="step-mess">
                      <span className="step-count">Step 2 :</span> Complete the
                      account open proceedings.
                    </p>
                  </li>
                  <li className="step-item">
                    <p className="step-mess">
                      <span className="step-count">Step 3 :</span> Once your demat account is ready and active, integrate your account with the Hedged platform to continue placing orders seamlessly.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="help-wrap">
                <span className="help-heading">Need Help?</span>
                <div className="help-form">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    className="form"
                  >
                    <div className="input-form">
                      <label className="input-label">
                        Email<span className="mandatory-sign">*</span>
                      </label>

                      <div className="form-group">
                        <TextField
                          id="email"
                          // label="Email"
                          name="email"
                          // variant="filled"
                          type="email"
                          autoComplete="off"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                          validation={validation}
                        />

                        {validation &&
                        validation.touched.email &&
                        validation.errors.email ? (
                          <FormFeedback
                            type="invalid"
                            style={{ color: "red", marginTop: "3px" }}
                          >
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className="input-form">
                      <label className="input-label">
                        Contact Number<span className="mandatory-sign">*</span>
                      </label>
                      <div className="form-group">
                        <TextField
                          id="mobile"
                          // label="Mobile"
                          // variant="filled"
                          type="tel"
                          name="mobile"
                          autoComplete="off"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.mobile || ""}
                          invalid={
                            validation.touched.mobile &&
                            validation.errors.mobile
                              ? true
                              : false
                          }
                          validation={validation}
                        />

                        {validation &&
                        validation.touched.mobile &&
                        validation.errors.mobile ? (
                          <FormFeedback
                            type="invalid"
                            style={{ color: "red", marginTop: "3px" }}
                          >
                            {validation.errors.mobile}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className="input-form">
                      <label className="input-label">
                        Telegram User ID
                        <span className="mandatory-sign">*</span>
                      </label>
                      <div className="form-group">
                        <TextField
                          id="telegramId"
                          // variant="filled"
                          type="text"
                          name="telegramId"
                          autoComplete="off"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.telegramId || ""}
                          invalid={
                            validation.touched.telegramId &&
                            validation.errors.telegramId
                              ? true
                              : false
                          }
                          validation={validation}
                        />

                        {validation &&
                        validation.touched.telegramId &&
                        validation.errors.telegramId ? (
                          <FormFeedback
                            type="invalid"
                            style={{ color: "red", marginTop: "3px" }}
                          >
                            {validation.errors.telegramId}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className="btn-wrap">
                      <button className="btn">Contact Me</button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default PaytmRequestModel;
