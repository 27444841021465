import React from "react";
import { captureEvent } from "../../../utils/helper";
import { useHistory } from "react-router-dom";
import { getFromStorage } from "../../../utils/storage";

// import logo from '../logo/bg-siteLogo.svg';

const Footer = () => {
  document.title = "Hedged";
  const history = useHistory();
  const userDetails = getFromStorage("userDetails")

  const handleTerms = () => {
    // history.push("/terms-conditions");
    window.open("/terms-conditions", "_self");
    captureEvent("clicked to navigate terms of use");
  };

  const handlePriacy = () => {
    // history.push("/privacy-policy");
    window.open("/privacy-policy", "_self");
    captureEvent("clicked to navigate to privacy policy");
  };

  return (
    <div className="comp-footer">
      <div className="container">
        <div className="left-wrap">
          <a className="logo" href="/#">
            <img
              src="/assets/images/logo.svg"
              width="158"
              height="32"
              alt="Logo of Hedged"
            />
          </a>
          <span className="reg-no">SEBI Registered RA INH000009825</span>
          <span className="cin-no">CIN Number : U74999MH2021PTC365248</span>
          <div>
            <p className="foot-address">
              1st floor & 2nd floor, 175,
              KAGALWALA HOUSE, Plot no-1,
              CST road KALINA, BKC, SANTACRUZ
              (E), Mumbai city 400098
            </p>
          </div>
        </div>
        <div className="footer-link">
          <div className="col">
            <ul className="list">
            {(userDetails?.planid == 6 || userDetails?.planid == 7 || userDetails?.planid == 8) ?
              <li className="item">
                <a href="/aos">Ace of Spades</a>
              </li>
              :
              <li className="item">
              <a href="/inbox">Inbox</a>
              </li>
              }
              {/* <li className="item">
                <a href="/inbox">Inbox</a>
              </li> */}
              <li className="item">
                <a href="/livefunds">'Simply Hedged' Funds</a>
              </li>
              <li className="item">
                <a href={userDetails ? "/hedgedTVList":"/login" }>Hedged TV</a>
              </li>
              <li className="item">
                <a href="/news">News</a>
              </li>
              <li className="item">
                <a href="https://blog.hedged.in/" target="_blank">
                  Blogs
                </a>
              </li>
              {(userDetails?.planid == 6 || userDetails?.planid == 7 || userDetails?.planid == 8) ?
              <></>
               :
              <li className="item">
                <a href="/subscription">Subscription</a>
              </li>}
            </ul>
          </div>
          <div className="col">
            <ul className="list">
              <li className="item">
                <a href="faqs">Faqs</a>
              </li>
              <li className="item">
                <a href="/about-us">About us</a>
              </li>
              <li className="item">
                <button onClick={handleTerms}>Terms of Use</button>
              </li>
              <li className="item">
                <button onClick={handlePriacy}>Privacy Policy</button>
              </li>

              <li className="item">
                <a href="/contact">Contact us</a>
              </li>
            </ul>
          </div>
          <div className="col">
            <ul className="list">
              <li className="item">
                <a href="/terminology">Terminology</a>
              </li>
              <li className="item">
                <a href="/investor-charter">Investor Charter</a>
              </li>
              <li className="item">
                <a href="/complaint-status">Complaint Status</a>
              </li>
              <li className="item">
                <a href="/grievance-redressal">Grievance Redressal</a>
              </li>
              {/* <li className="item">
                <a href="/register">Register For Webinar</a>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="connect-us">
          <h2 className="title">Have Questions?</h2>
          <p className="desc">
            In case you have any doubts or queries, kindly reach out to us on
            the below email address
          </p>
          <a href="mailto:911@hedged.in" className="link">
            911@hedged.in
          </a>
          <div className="social-links">
            <a
              href="https://www.facebook.com/people/IAm_Hedged/100089043495879/"
              className="link"
              target="_blank"
              aria-label="facebook"
            >
              <span className="icon icon-facebook"></span>
            </a>
            <a
              href="https://www.instagram.com/iam_hedged/"
              className="link"
              target="_blank"
              aria-label="instagram"
            >
              <span className="icon icon-instagram"></span>
            </a>
            <a
              href="https://www.youtube.com/channel/UC8IIWjj-6nGR9OiPpdWgpaQ"
              className="link"
              target="_blank"
              aria-label="youtube"
            >
              <span className="icon icon-youtube"></span>
            </a>
            <a
              href="https://twitter.com/IAm_Hedged"
              className="link"
              target="_blank"
              aria-label="twitter"
            >
              <span className="icon icon-twitter"></span>
            </a>
            <a 
              // href="https://www.linkedin.com/in/iamhedged-705057260"
              href="https://www.linkedin.com/company/iamhedged/"
              className="link"
              target="_blank"
              aria-label="linkedin"
            >
              <span className="icon icon-linkedin"></span>
            </a>
          </div>
      </div>
        </div>
      <div className="footer-desc">
        <p className="footer-paragraph">Investment in securities market are subject to market risks. Read all the related
          documents carefully before investing. Registration granted by SEBI and certification from NISM in no way
          guarantee performance of the intermediary or provide any assurance of returns to investors.<a
            href="https://smartodr.in/" target="_blank" className="clr-primary"> https://smartodr.in/</a></p>

        </div>
    </div>
  );
};

export default Footer;
