import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getFromStorage } from "../../../utils/storage";
import { useMobileToWeb } from "../../../store/Positions/mobileToWeb";

const NonAuthHeader = () => {
  const userdetails = getFromStorage("userDetails");
  const isMobileToWeb = useMobileToWeb(
    (state) => state.isMobileToWeb
  );

  const history = useHistory();
  const location = useLocation();

  const handleHedgedResult = () => {
    if (userdetails == null) {
      history.push("/login");
    }
  };
  return (
    <header className="comp-header">
      <div className="container">
        <a className="logo" href="/">
          <img
            src="/assets/images/logo.svg"
            width="158"
            height="32"
            alt="Logo of Hedged"
          />
        </a>

        {/* <div className="navbar">
          <ul className="list">
              <li className="item"><a href="/#">Inbox</a></li>
              <li className="item"><a href="/#">Live Funds</a></li>
              <li className="item"><a href="/#">Hedge TV</a></li>
              <li className="item"><a href="/#">News</a></li>
              <li className="item"><a href="/#">Subscription</a></li>
          </ul>
      </div>

      <a href="/#" className="btn typ-small btn-spl hedged-result">Hedged Results</a> */}

        <div className="action-btn">
          <button
            className="btn typ-small btn-spl hedged-result"
            onClick={handleHedgedResult}
          >
            Hedged Results
          </button>
          {(isMobileToWeb || getFromStorage("mobiletoweb")) ? <></> :
            <>
              {location?.pathname !== "/signup" &&
                <Link className="btn typ-small" to="/signup">
                  Signup
                </Link>}
              {location?.pathname !== "/login" &&
                <Link className="btn typ-small btn-brdr" to="/login">
                  <span className="btn-inner">
                    <span className="text">Login</span>
                  </span>
                </Link>}
              {location?.pathname !== "/register" &&
                <Link className="btn typ-small btn-brdr" to="/register">
                  <span className="btn-inner">
                    <span className="text">Live Session</span>
                  </span>
                </Link>}

            </>

          }


        </div>

        {/* <div className="user-dropdown">
          <div className="user-details">
              <span className="icon crown"><img src="assets/images/crown.svg" alt="" /></span>
              <span className="username">Irfan Khan</span>
              <span className="icon user-setting"><img src="assets/images/manage-account.svg" alt="" /></span>
          </div>
          <div className="dropdown">
              <ul className="list">
                  <li className="item"><a href="/#">Logout</a></li>
              </ul>
          </div>
      </div> */}
      </div>
    </header>

    /*  <div
      style={{
        background: 'black',
        color: 'white',
        height: '40px',

        textAlign: 'center',
        padding: '14px 16px',
        textDecoration: 'none',
        fontSize: '17px',
      }}
    >
      <span style={{ float: 'left', margin: '10px 0' }}>HEDGED</span>

      <ul
        className="sub-menu"
        style={{
          listStyle: 'none',
          display: 'flex',
          justifyContent: 'space-evenly',
          color: 'white',
        }}
      >
        <li>
          <Link to="/signup" style={{ color: 'white', textDecoration: 'none' }}>
            {'Signup'}
          </Link>
        </li>
        <li>
          <Link to="/signin" style={{ color: 'white', textDecoration: 'none' }}>
            {'Signin'}
          </Link>
        </li>
      </ul>
    </div>*/
  );
};

export default NonAuthHeader;
