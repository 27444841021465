import { create } from "zustand";

export const useBrokerLoginStore = create((set, get) => ({
  openBroker: false,
  brokerList: [],
  brokerUserList: [],
  userData: null,
  openConfirmModel: false,
  broker: "",
  openRequestModel: false,
  openErrorModel: false,
  openPersonalDetailModel: false,
  personalDetailsData: null,


  setOpenBroker: (payload) => {
    set((state) => ({
      openBroker: payload,
    }));
  },

  setOpenConfirmModel: (payload) => {
    const { open, loginBroker } = payload
    set((state) => ({
      openConfirmModel: open,
      broker: loginBroker
    }));
  },

  setOpenRequestModel: (payload) => {
    // console.log("check", payload);
    set((state) => ({
      openRequestModel: payload
    }));
  },
  setOpenPersonalDetailModel: (payload) => {
    // console.log("check", payload);
    set((state) => ({
      openPersonalDetailModel: payload
    }));
  },
  setOpenErrorModel: (payload) => {
    // console.log("check", payload);
    const { open, broker, prevUrl } = payload
    set((state) => ({
      openErrorModel: open,
      broker: broker,
      url: prevUrl
    }));
  },

  setBrokerList: (payload) => {
    set((state) => ({
      brokerList: payload,
    }));
  },
  setBrokerUserList: (payload) => {
    set((state) => ({
      brokerUserList: payload,
    }));
  },
  setUserData: (payload) => {
    set((state) => ({
      userData: payload,
    }));
  },

  setPersonalDetailsData: (payload) => {
    set((state) => ({
      personalDetailsData: payload,
    }));
  },
}));
