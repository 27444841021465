import { create } from "zustand";
import { getFromStorage } from "../../utils/storage";

// const userDetails = getFromStorage("userDetails")
export const useMobileToWeb = create((set, get) => ({
    isMobileToWeb: false,

    setIsMobileToWeb: (payload) => {
        set((state) => ({
            isMobileToWeb: payload,
        }));
    },

}));
