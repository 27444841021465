import axios from 'axios';
import { constants } from './helper';
//production
//devlopment
const url = constants?.baseUrl;
const instance = axios.create({
  baseURL: url,
  auth: {
    username: 'appuser@injin.com',
    password: 'N_w?Z92w%-BuLvKX',
  },
});
export default instance;
